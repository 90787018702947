import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { RouterModule } from '@angular/router';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@core/auth/auth.module';
import { LoginComponent } from '@core/auth/components/login/login.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PlannerModule } from '@modules/planner/planner.module'
import {OverviewModule} from "@modules/overview/overview.module";
import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FieldReportingModule } from '@modules/field-reporting/field-reporting.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { UserInfoResolver } from '@shared/services/user/user.info.resolver';

@NgModule({ declarations: [
        AppComponent
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AuthModule,
        PlannerModule,
        OverviewModule,
        FieldReportingModule,
        AppRoutingModule,
        LeafletModule,
        SharedModule,
        BrowserAnimationsModule, // required animations module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot([
            { path: 'login', component: LoginComponent },
        ]),
        SweetAlert2Module.forRoot(),
        NgbModule,
        NgChartsModule], providers: [DatePipe, UserInfoResolver, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }

// required for Ahead Of Time compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
