import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { filter, forkJoin, map, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core'
import { WorkOrder } from '@shared/models/work-order';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss']
})
export class ProjectPageComponent implements OnInit, OnDestroy {

  timeInterval: Subscription | undefined
  componentDestroyed$: Subject<boolean> = new Subject()
  userLang: any = null;

  //editor booleans
  infoEditor = false
  timeEditor = false
  msaEditor = false
  excelEditor = false

  //loading booleans
  msaEstimatesLoading = false
  estimatesLoading = true
  importedLoading = true

  estimatesSet = Array()
  calculatedTime: any

  projectId: any | undefined
  userid: any | undefined

  projectData: any
  projectName: string | undefined
  projectInfo: string = ""
  workorderCount: any
  projectTimeEstimates: any = []
  projectTimeEstimatesTemp
  projectMsaEstimates: any
  projectMailTemplates: any
  projectLogo: any
  projectCreated: string = ""
  projectMsaObject: any
  msaNames: any
  allEstimates: any
  timeEstimatesObj: any
  msaTimeEstimatesObjs: any = []
  ngModelArray: any[] = [];
  selectedMsa: any
  msaDefaults = Array()
  selectedMsaId: any;
  chosenEstimateObject: any
  myParam: string | undefined;
  showProjectInfo: boolean = false;

  //queue stuff
  queueProgress = 0
  queues = Array()
  activeWorkorderQueues = false
  queFinished: boolean = false

  workorders: Array<WorkOrder> = {} as WorkOrder[]
  limitValue: number = 10

  defaultGroupSizeObjects: any = []
  defaultGroupSizes: any = []
  customerId: number  | null = null
  invertersCount: number = 0
  inverterLoader: boolean = true

  constructor(
    private plannerProjectService: PlannerProjectService,
    private userService: UserService,
    private workOrderService: WorkOrderService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.userLang = localStorage.getItem('userlanguage');
    this.getProjectInfo()
  }

  ngOnDestroy(): void {
    this.timeInterval?.unsubscribe()
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Gets all information of current project and inserts values to variables.
   * After the subscribe completes, calls other methods to run.
   */
  getProjectInfo() {
    this.projectMsaEstimates = []
    this.route.params.subscribe((params: Params) => this.myParam = params['newproject'])
    this.getCurrentProjectId(() =>
      this.plannerProjectService.getProjectById(this.projectId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.customerId = data.customer_id
          this.projectCreated = data.created
          this.projectData = data
          this.projectName = data.name
          this.projectInfo = data.information
          // this.projectTimeEstimates = data.default_time_parameters
          this.projectTimeEstimatesTemp = data.default_time_parameters
          this.defaultGroupSizeObjects = data.default_group_sizes;
          this.getProjectDefaultGroupSizes();
        })
        .add(() => {
          this.getWorkorders()
          this.calculateTimeEstimates()
          this.timeEstimatesJSONStringToArray()
        })
    )
  }

  getInvertersCount() {
    this.invertersCount = 0
    this.inverterLoader = true
    this.workOrderService.getInverters(this.projectId).subscribe({
      next: data => {
        this.invertersCount = data.length
        this.inverterLoader = false
      },
      error: err => console.error(err.toString())
    })
  }

  newProjectNextBtn() {
    this.router.navigate(['planner/project'])
  }

  enableTimeEditor() {
    this.timeEditor = true
  }

  enableMsaEditor() {
    this.msaEditor = true
  }

  disableTimeEditor() {
    this.timeEditor = false
    this.msaEditor = false
    this.getProjectInfo()
  }

  /**
   * This is a very custom function constructs a 'time-estimate-json' object from form values and sends them to the backend.
   * @param f
   */
  saveChangesToEstimates(f: NgForm) {
    this.estimatesLoading = true
    let changedStatuses: any = []
    for (let key of Object.keys(f.value)) {
      let keyString = key.toString()
      let value = f.value[key]
      let id = Number(keyString.split('_')[1])

      if (keyString.includes('estimate')) {
        value = Number(value)
        if (this.timeEstimatesObj[id]) {
          if (this.timeEstimatesObj[id] != value) {
            this.timeEstimatesObj[id] = value
          }
        } else {
          this.timeEstimatesObj[id] = value
        }

      }
      if (key.toString().includes('color')) {
        if (this.projectData.statuses[id].color) {
          if (this.projectData.statuses[id].color != value) {
            this.projectData.statuses[id].color = value
            changedStatuses.push({ id: id, color: value })
          }
        } else {
          this.projectData.statuses[id].color = value
          changedStatuses.push({ id: id, color: value })
        }
      }
    }

    // todo save colors
    let timeEstimateJson = JSON.stringify(this.timeEstimatesObj)

    this.plannerProjectService.updateProjectTimeEstimates(this.projectId, timeEstimateJson)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(d => {
        this.plannerProjectService.updateProjectStatuses(changedStatuses)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(d => {

            this.timeEditor = false
            f.reset()
          }).add(() => {
            this.toastService.sendToast(true, this.translateService.instant('planner.projectPage.toast.timeEstimatesUpdated'))
            this.getProjectInfo()
          })
      })
  }

  saveChangesToDefaultGroupSizes(f: NgForm) {
    // Process the form values
    let defaultGroupSizeObjects = JSON.parse(this.defaultGroupSizeObjects) || {};

    for (let key of Object.keys(f.value)) {
      let keyString = key.toString();
      let value = f.value[key];
      let id = Number(keyString.split('_')[1]);

      if (keyString.includes('estimate')) {
        value = Number(value);
        defaultGroupSizeObjects[id] = value;
      }
    }

    // Convert the processed values to JSON
    let defaultGroupSizeJSON = JSON.stringify(defaultGroupSizeObjects);

    // Save the JSON to the server using your service
    this.plannerProjectService.updateDefaultGroupSizes(this.projectId, defaultGroupSizeJSON)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(d => {
        // On successful save
        this.msaEditor = false;
        f.reset();
      }, error => {
        // Handle any error from the API call
        console.error("Error updating default group sizes:", error);
      }, () => {
        // Once the save process (either success or error) is completed
        this.toastService.sendToast(true, this.translateService.instant('planner.projectPage.defaultGroupSizesUpdated'));
        this.getProjectInfo();  // Fetch updated project info
      });
  }

  /**
   * Gets current project id and calls a callback method to tell that it has finished
   * @param cb
   */
  getCurrentProjectId(cb) {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.projectId = data.current_project
          this.userid = data.id

          // cb() = callback to let the other function know that this one is finished
          cb()
        }
      );
  }

  /**
   * Counts the number of orders in current project
   *
   * @edit 13.12.2022
   * Enabled use of getWorkorderCount to get all meters count.
   * Added sort for workorders to get ordered by SYSTEM ID
   * @author Jesse Lindholm
   * 
   * @edit 19.8.2024
   * Modified for Earth's new workorder api-call
   */
  getWorkorders() {
    this.workorderCount = 0;
    this.invertersCount = 0;
    this.inverterLoader = true;
    this.importedLoading = true;
  
    forkJoin({
      workorders: this.workOrderService.getWorkOrders(null, 1),
      inverters: this.workOrderService.getWorkOrders(null, 2)
    })
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: ({ workorders, inverters }) => {
        this.workorders = workorders;
        this.workorderCount = workorders.length;
        this.workorders.sort((a, b) => a.id - b.id);
  
        this.invertersCount = inverters.length;
        this.inverterLoader = false;
        this.importedLoading = false;
      },
      error: () => {
        this.inverterLoader = false;
        this.importedLoading = false;
      }
    });
  }

  /**
   * Converts projectTimeEstimates string into an object.
   * Then converts the object to an array of objects so that ngFor can loop through it.
   */
  timeEstimatesJSONStringToArray() {
    this.timeEstimatesObj = JSON.parse(this.projectTimeEstimatesTemp) || {}
    // let mapped = {}
    let mapped: any[] = []
    for (let statusId in this.projectData['statuses']) {
      // Check if the statusId exists in timeEstimatesObj
      let estimateMinutes = this.timeEstimatesObj[statusId] || 0;  // Will be 0 if not found in timeEstimatesObj
      let definition
      let defjson = JSON.parse(this.projectData.statuses[statusId].definition) || {}
      if (this.userLang) {
        definition = defjson[this.userLang] ||
          defjson['en'] ||
          undefined;
      } else {
        definition = defjson['en'] || undefined;
      }
      if (this.projectData.statuses[statusId].state === 1) estimateMinutes = 0
      mapped.push({
        id: statusId,
        estimate: estimateMinutes,
        state: this.projectData.statuses[statusId].state,
        definition: definition,
        color: this.projectData.statuses[statusId].color,
        type: this.projectData.statuses[statusId].type
      })
    }

    this.projectTimeEstimates = mapped
    this.estimatesLoading = false

  }

  sortByType(array: any[]) {
    let filteredArray = this.filterDoneState(array)
    return filteredArray.sort((a,b) => (a.type - b.type))
  }

  /**
   * Fetches the msa-estimates for the project and builds usable variables from that.
   * Calls a few functions to build data for the UI after it finishes.
   */
  getMsaEstimates() {
    let msas = Array()
    let msaEstimates = Array()
    this.workOrderService.getMsas()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          if (data.length == 0) this.msaEstimatesLoading = false
          // Loop through data to set markers
          for (let i = 0; i < data.length; i++) {
            try {
              let mapped: any[] = []
              let msaTimeEstimatesObj = JSON.parse(<string>data[i].default_time_parameters) || {}
              this.msaTimeEstimatesObjs[data[i].id] = msaTimeEstimatesObj
              for (let statusId in this.projectData['statuses']) {
                // Check if the statusId exists in timeEstimatesObj
                let estimateMinutes = msaTimeEstimatesObj[statusId] || null;
                let definition
                let defjson = JSON.parse(this.projectData.statuses[statusId].definition) || {}
                if (this.userLang) {
                  definition = defjson[this.userLang] ||
                    defjson['en'] ||
                    undefined;
                } else {
                  definition = defjson['en'] || undefined;
                }

                mapped.push({
                  id: statusId,
                  estimate: estimateMinutes,
                  state: this.projectData.statuses[statusId].state,
                  definition: definition,
                  color: this.projectData.statuses[statusId].color,
                })
              }
              this.projectMsaEstimates[data[i].id] = mapped
              msas[i] = {
                "name": data[i].name,
                "id": data[i].id
              }
            } catch (error) {
              console.error('error!')
              continue
            }
          }
          this.msaNames = msas
          // this.projectMsaEstimates = msaEstimates
        }
      ).add(() => {
        this.setSelectedMsa()
        this.msaEstimatesLoading = false
      })
  }

  filterDoneState(states: any[]): any[] {
    return states.filter(s => 
        !(
            s.definition === 'Done' ||
            s.definition === 'Valmis'
        )
    );
}

  

  getProjectDefaultGroupSizes() {
    // Assuming you have this.projectData['statuses'] and this.userLang available in the component
    const groupSizes: any[] = [];
    for (let statusId in this.projectData['statuses']) {
        let definition;
        let defjson = JSON.parse(this.projectData.statuses[statusId].definition) || {};

        if (this.userLang) {
          definition = defjson[this.userLang] || defjson['en'] || undefined;
        } else {
          definition = defjson['en'] || undefined;
        }
        const statusIdNumber = Number(statusId)
        let currentPhaseGroupSizeEstimate = 1;
        try {
            // Parse the JSON object
            let parsedObject = JSON.parse(this.defaultGroupSizeObjects);

            // Check if statusIdNumber exists
            currentPhaseGroupSizeEstimate = parsedObject && parsedObject[statusIdNumber] ? parsedObject[statusIdNumber] : 1;

        } catch (error) {
        }

        groupSizes.push({
          id: statusId,
          definition: definition,
          estimate: currentPhaseGroupSizeEstimate,  // Initialize with a default estimate (change if needed)
          color: this.projectData.statuses[statusId].color,
          type: this.projectData.statuses[statusId].type
        });

    }
    this.defaultGroupSizes = groupSizes;
  }

  /**
   * Calculates time for all workorders based on project default time estimates.
   *
   * @param defaultTimes
   * defaultTimes is the default_time_parameters of the project
   *
   * @param time_parameters
   * time_parameters is an array of work_order time parameters.
   */
  calculateTimeEstimates() {

    this.plannerProjectService.getProjectTime(this.projectId, 1)
    .pipe(
      takeUntil(this.componentDestroyed$),
      switchMap(data => {
        // Get workorders total minutes
        let total = data.workorders_total;
        // Switch to the second observable (for inverters total minutes)
        return this.plannerProjectService.getProjectTime(this.projectId, 2).pipe(
          takeUntil(this.componentDestroyed$),
          // Map the result to add inverters total to workorders total
          map(data => {
            total += data.inverters_total;
            return total;
          })
        );
      })
    )
    .subscribe(total => {
      // Calculate in hours and minutes
      this.minutesToHoursAndMinutes(total);
    });
  }

  /**
   * Calculates minutes/hours from minutes.
   * @param time
   */
  minutesToHoursAndMinutes(time) {
    this.calculatedTime = this.workOrderService.minutesToHoursAndMinutes(time)
  }

  /**
   * This one gets msa id as parameter and builds an msa time-estimate object for that specific msa
   * The object 'selectedMsa' is used in rendering data to the UI
   * @param id
   */
  msaSelect(id) {
    this.selectedMsaId = Number(id)
    if (id && this.projectMsaEstimates[id]) {
      this.selectedMsa = this.projectMsaEstimates[id]
    } else {
      // If selectedMsa is null, UI will say that there are no time estimates fot that specific MSA
      this.selectedMsa = null
    }
  }

  /**
   * Builds the default selection msa time-estimate object
   */
  setSelectedMsa() {
    if (this.selectedMsaId) {
      this.msaSelect(this.selectedMsaId)
    } else if (this.projectMsaEstimates.length != 0) {
      for (let i = 0; i < this.projectMsaEstimates.length; i++) {
        if (this.projectMsaEstimates[i]) {
          this.selectedMsaId = i
          this.msaSelect(this.selectedMsaId)
          break
        }
      }
    }

    if (!this.selectedMsaId) {
      // If selectedMsa is null, UI will say that there are no time estimates fot that specific MSA
      this.selectedMsa = null
    }
    this.msaEstimatesLoading = false
  }

  /**
   * Function that changes the chosen default estimate in msa settings, when adding a new msa.
   * @param value
   */
  setChosen(value) {
    // this.chosenEstimateObject = this.allEstimatesObject[value]
  }

  /**
   * Function that removes a time-parameter from an MSA, it needs the index of the time-estimate and its name.
   * @param index
   * @param name
   */
  deleteMsaEstimate(index, name) {
    Swal.fire({
      title: this.translateService.instant('planner.projectPage.toast.delete1') + name + this.translateService.instant('planner.projectPage.toast.delete2'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDismissed) {
        // Do nothing
      } else if (result.isConfirmed) {

        this.msaEstimatesLoading = true

        let msaArray = JSON.parse(JSON.stringify(this.selectedMsa))

        for (let y = 0; y < msaArray.length; y++) {
          delete msaArray[y].name
          delete msaArray[y].checked

          for (let i = 0; i < msaArray[y].estimates.length; i++) {
            delete msaArray[y].estimates[i].name
          }
        }

        msaArray.splice(index, 1)

        msaArray.splice(0, msaArray.lenght);
        this.workOrderService.updateMsaTimes(JSON.stringify(msaArray), this.selectedMsaId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            () => {
              this.getProjectInfo()
            }
          )
      }
    })
  }


  /**
   * Sets the innerHtml of time estimate icon buttons
   * @param estimateObject
   * @param index
   * @returns
   */
  setElementType(estimateObject, index) {
    let highlighted = ''

    if (estimateObject[index]['highlighted']) {
      highlighted = estimateObject[index]['highlighted']
    }

    if (highlighted == 'color') {
      return '<img src="assets/icons/attributes/colors.svg">'
    } else if (highlighted == 'shape') {
      return '<img src="assets/icons/attributes/shapes.svg">'
    } else if (highlighted == 'dots') {
      return '<img src="assets/icons/attributes/dots.svg">'
    } else {
      return this.translateService.instant('basic.notSelected')
    }
  }

  /**
   * Sets element type based on time estimate icon button click
   * @param estimateObject
   * @param index
   * @param highlighted
   */
  setSelectedElementType(estimateObject, index, highlighted) {

    if (estimateObject[index]) {
      estimateObject[index]['highlighted'] = highlighted
    } else {
      estimateObject['highlighted'] = highlighted
    }
  }

  /**
   * Sets icons for time estimate dropdown options
   * @param estimateObject
   * @param firstIndex
   * @param secondIndex
   * @param attribute
   */
  setSelectedIconAttribute(estimateObject, firstIndex, secondIndex, attribute) {
    if (estimateObject[firstIndex]) {
      estimateObject[firstIndex].estimates[secondIndex]['attribute'] = attribute
    } else {
      estimateObject.estimates[secondIndex]['attribute'] = attribute
    }
  }


  /**
   * Sets the selected icon based on parameters (edit view)
   * @param estimateObject
   * @param firstIndex
   * @param secondIndex
   * @returns
   */
  setIcon(estimateObject, firstIndex, secondIndex) {

    let attribute = ''

    if (estimateObject[firstIndex]) {
      attribute = estimateObject[firstIndex].estimates[secondIndex]['attribute']
    } else {
      attribute = estimateObject.estimates[secondIndex]['attribute']
    }

    if (attribute == 'cross') {
      return '<img src="assets/select-icons/select_gray_cross.svg">'
    } else if (attribute == 'diamond') {
      return '<img src="assets/select-icons/select_gray_diamond.svg">'
    } else if (attribute == 'hexagon') {
      return '<img src="assets/select-icons/select_gray_hexagon.svg">'
    } else if (attribute == 'octagon') {
      return '<img src="assets/select-icons/select_gray_octagon.svg">'
    } else if (attribute == 'plumbob') {
      return '<img src="assets/select-icons/select_gray_plumbob.svg">'
    } else if (attribute == 'reverse-triangle') {
      return '<img src="assets/select-icons/select_gray_reverse_triangle.svg">'
    } else if (attribute == 'ball') {
      return '<img src="assets/select-icons/select_gray_ball.svg">'
    } else if (attribute == 'square-star') {
      return '<img src="assets/select-icons/select_gray_square_star.svg">'
    } else if (attribute == 'square') {
      return '<img src="assets/select-icons/select_gray_square.svg">'
    } else if (attribute == 'star') {
      return '<img src="assets/select-icons/select_gray_star.svg">'
    } else if (attribute == 'triangle') {
      return '<img src="assets/select-icons/select_gray_triangle.svg">'
    } else if (attribute == 'north-star') {
      return '<img src="assets/select-icons/select_gray_north_star.svg">'
    } else {
      return 'Select icon'
    }
  }

  /**
   * Sets the icons for time estimates (non edit view)
   * @param attribute
   * @returns
   */
  setIconByAttribute(attribute) {

    if (attribute == 'cross') {
      return '<img src="assets/select-icons/select_gray_cross.svg">'
    } else if (attribute == 'diamond') {
      return '<img src="assets/select-icons/select_gray_diamond.svg">'
    } else if (attribute == 'hexagon') {
      return '<img src="assets/select-icons/select_gray_hexagon.svg">'
    } else if (attribute == 'octagon') {
      return '<img src="assets/select-icons/select_gray_octagon.svg">'
    } else if (attribute == 'plumbob') {
      return '<img src="assets/select-icons/select_gray_plumbob.svg">'
    } else if (attribute == 'reverse-triangle') {
      return '<img src="assets/select-icons/select_gray_reverse_triangle.svg">'
    } else if (attribute == 'ball') {
      return '<img src="assets/select-icons/select_gray_ball.svg">'
    } else if (attribute == 'square-star') {
      return '<img src="assets/select-icons/select_gray_square_star.svg">'
    } else if (attribute == 'square') {
      return '<img src="assets/select-icons/select_gray_square.svg">'
    } else if (attribute == 'star') {
      return '<img src="assets/select-icons/select_gray_star.svg">'
    } else if (attribute == 'triangle') {
      return '<img src="assets/select-icons/select_gray_triangle.svg">'
    } else if (attribute == 'north-star') {
      return '<img src="assets/select-icons/select_gray_north_star.svg">'
    } else {
      return ''
    }
  }



  /**
   * 3.11.2022
   * Change limitValue, which controls how many workorders are shown in list.
   * @param newValue new value for limit
   * @author Jesse Lindholm
   */
  changeLimit(newValue: number) {
    if (newValue !== this.limitValue) this.limitValue = newValue
  }


  saveInfo() {
    this.plannerProjectService.updateProjectInformation(this.projectId, this.projectInfo)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.showProjectInfo = false
      })
  }
}

