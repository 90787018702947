import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  RendererFactory2,
  ViewChildren
} from '@angular/core';
import 'leaflet-draw';
import { MapService } from '@modules/planner/services/map/map.service';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { UserService } from '@shared/services/user/user.service';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { catchError, forkJoin, Observable, of, Subject, Subscription, switchMap, takeUntil, tap } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { WorkOrder } from '@shared/models/work-order';
import { Status } from "@shared/models/status";
import { TeamsService } from '@modules/planner/services/teams/teams.service';

declare const L: any;

@Component({
  selector: 'app-weekly-planning-map',
  templateUrl: './weekly-planning-map.component.html',
  styleUrls: ['./weekly-planning-map.component.scss']
})
export class WeeklyPlanningMapComponent implements OnInit, OnDestroy {

  @ViewChildren('detailsRef') detailsRefs!: QueryList<ElementRef>;



  componentDestroyed$: Subject<boolean> = new Subject()
  map: any
  mapReady = new Subject<void>();
  canvasRenderer: any;
  invertersDrawn: boolean = false
  workordersDrawn: boolean = false

  projectId: any
  userLang: any
  loading: boolean = false
  customerId = 0;

  infoBox = "assets/icons/category_gray_24dp.svg"
  spinner = true;
  tableSpinner = true;

  //selected stuff
  selectedTime = '0'
  selectedMeters = Array()
  selectedSpinner = false
  timeSubscriptions: Subscription = new Subscription()
  selectedFilter = "filterOff"
  selectedWorkerteam = "filterOff"

  //statuses
  selectSlotMode = false
  bypassMode = false
  statuses: any[] = [];
  selectedStatus: Status = { id: 0, project_id: 0, state: 0, definition: '', color: '#000000'} as Status // Now whole selected status

  //calendar
  calendarData: any
  calendarDays: any
  calendarToRender = new Map<string, Array<any>>();
  slotRows = Array();
  lastDay: any
  weekNr: any
  originalCalendarData: any
  allCalendars;

  openedSlotId: string | null = null;
  private renderer!: Renderer2;
  private unsubscribeClickOutside?: () => void;

  originalWorkOrders: WorkOrder[] = [];
  workOrders: WorkOrder[] = [];

  selectedMarkers: any[] = [];
  groups = Array();
  workerteamsLoading = true;

  addToSingleSlot: boolean = false;

  // draw stuff
  editableLayers: any
  drawControl: any
  drawEnabled: any
  drawCreatedCalled: boolean = false;
  showCancelCreate: boolean = false;

  calendarDataByCalendarId = {};
  otherSlots: any = []

  calendarsDataArray: CalendarInfo[] = [];
  originalCalendarsDataArray: CalendarInfo[] = [];
  selectedCalendar: number = 1;

  workOrderMarkers: Map<number, L.Marker> = new Map();
  inverterMarkers: Map<number, L.Marker> = new Map();

  globalStartDate;
  globalLastDate;

  defaultTimeEstimates;
  defaultGroupSizes;

  expandedSlotId: number | null = null;
  showColors = false;
  interruptedColor = "#ff1122";

  rowNumberMarkers;
  rowNumbers;

  originalInverters: WorkOrder[] = []
  inverters: WorkOrder[] = []

  tries: number = 0;
  project
  mainSelection: number = 0

  state: any = null


  constructor(
    private mapService: MapService,
    private workOrderService: WorkOrderService,
    private userService: UserService,
    private plannerProjectService: PlannerProjectService,
    private calendars: CalendarsService,
    private translateService: TranslateService,
    private rendererFactory: RendererFactory2,
    private teamsService: TeamsService
  ) { }



  ngOnInit(): void {
    this.setUpCustomRectangleCanvas();
    this.setUpCustomTextMarkerCanvas();

    this.setupInitialValues();

    let item = localStorage.getItem('phaseState')
    this.getCurrentProjectInfo().pipe(
      switchMap(() => this.getWorkOrders(null, 1)),
      switchMap(() => this.getInverters(null, 2)),
      switchMap(() => this.getStatuses(this.projectId)),
      switchMap(() => this.getRowNumbers(this.projectId)),
      tap(() => {
        if (item) this.applyPhaseState(item)
      }),
      takeUntil(this.componentDestroyed$)
    ).subscribe({
      next: () => {
        this.initMap();
      },
      error: error => {
        console.error('Error fetching work orders, statuses, or row numbers:', error);
      }
    });

    // Subscribe to the mapReady subject to draw row numbers once the map is initialized
    this.mapReady.subscribe(() => {
      this.drawRowNumbersOnMap();
    });

    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  applyPhaseState(json) {
    if (json) {
      let item = JSON.parse(json)
      this.invertersDrawn = false
      this.workordersDrawn = false
      this.onStatusChange(item, false)
    }
  }

  savePhaseLocal(status) {
    localStorage.setItem('phaseState', JSON.stringify(status))
  }



  // Destroy subscriptions on this component when leaving page
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
    if (this.unsubscribeClickOutside) {
      this.unsubscribeClickOutside();
    }
  }


  setupInitialValues(): void {
    this.userLang = localStorage.getItem('userlanguage');
    this.globalStartDate = this.getFirstDayOfWeek(new Date());
    this.globalLastDate = this.getLastDayOfWeek(new Date());
    this.setWeekFromDate(this.globalStartDate);
  }



  getStatuses(projectId: number): Observable<any> {
    return this.plannerProjectService.getStatuses(projectId).pipe(
        tap(response => {
            if (response.status === 'success') {
                this.statuses = [];
                let first = true;

                response.message.forEach((status: any) => {
                    if ((status.type == 1 && this.mainSelection === 0) || (status.type == 2 && this.mainSelection === 1) || (status.type == 3 && this.mainSelection === 2)) {
                        let definition;
                        let defjson = JSON.parse(status.definition) || {};
                        definition = this.userLang ? defjson[this.userLang] || defjson['en'] : defjson['en'];
                        status.definition = definition;
                        this.statuses.push(status);
                        if (first && status.state === 2) {
                            first = false;
                            this.selectedStatus = status;
                            if (!this.state) {
                              this.state = status
                              localStorage.setItem('phaseState', JSON.stringify(this.selectedStatus));
                            }
                        }
                    }
                });

                // Remove first and last status if needed
                this.statuses = this.statuses.slice(1, this.statuses.length - 1);

                // Ensure selectedStatus matches an item in statuses
                if (!this.statuses.some(status => status === this.selectedStatus)) {
                    this.selectedStatus = this.statuses[0]; // or some other default
                }
                this.getGroupsWithCalendars();
                this.timeEstimatesJSONStringToArray();
            } else {
                console.error(response.message);
                throw new Error(response.message);
            }
        }),
    );
}

  /**
   * Converts projectTimeEstimates string into an object.
   * Then converts the object to an array of objects so that ngFor can loop through it.
   */
  timeEstimatesJSONStringToArray() {
    let timeEstimatesObj = JSON.parse(this.project.default_time_parameters) || {}
    let mapped: any[] = []
    if (!this.statuses) {
      return false
    }
    for (let key in this.statuses) {
      let status = this.statuses[key]
      // Check if the statusId exists in timeEstimatesObj
      let estimateMinutes = timeEstimatesObj[status.id] || 0;  // Will be 0 if not found in timeEstimatesObj
      let definition =

        mapped.push({
          id: status.id,
          estimate: estimateMinutes,
          state: status.state,
          definition: status.definition,
          color: status.color,
        })
    }

    this.defaultTimeEstimates = mapped
    return true

  }

  defaultGroupSizesJSONStringToArray() {
    try {
      let groupSizesObj = JSON.parse(this.project.default_group_sizes)
      if (typeof groupSizesObj === 'object' && groupSizesObj !== null) {
        this.defaultGroupSizes = groupSizesObj
      } else {
        this.defaultGroupSizes = {}
      }
    } catch (e) {
      this.defaultGroupSizes = {}
    }
  }

  /**
   * Gets current project id and calls a callback method to tell that it has finished
   * @param cb
   */
  getCurrentProjectInfo(): Observable<any> {
    return this.userService.getUserInfo()
      .pipe(
        takeUntil(this.componentDestroyed$),
        tap(data => {
          this.projectId = data.current_project;
          this.customerId = data.customer_id;
        }),
        switchMap(data => {
          if (!this.projectId) {
            console.error('getCurrentProjectId - projectId is null or undefined');
            return of(null);
          }
          return this.plannerProjectService.getProjectById(this.projectId).pipe(
            tap(projectData => {
              this.project = projectData
              this.defaultGroupSizesJSONStringToArray();
              this.timeEstimatesJSONStringToArray();
            })
          );
        }),
        catchError(error => {
          console.error('getCurrentProjectId - error:', error);
          return of(null);
        })
      );
  }

  refreshCalendars(): Observable<any> {

    // Check if allCalendars is defined and has elements
    if (!this.allCalendars || this.allCalendars.length === 0) {
      return of(null);
    }

    const calendarObservables = this.allCalendars.map(calendar =>
      this.getCalendarById(calendar.id, true, null)
    );

    return forkJoin(calendarObservables).pipe(
      catchError(error => {
        console.error('refreshCalendars - error:', error);
        return of(null);
      })
    );
  }


  getGroupsWithCalendars() {
    this.workerteamsLoading = true;
    this.userService.getGroupsWithStatus(this.selectedStatus.id).subscribe(
      (data) => {
        this.groups = data;
        this.calendarsDataArray = this.originalCalendarsDataArray = [];

        if (this.groups && this.groups.length > 0) {
          // If there are groups, initialize calendars for worker teams
          //   const calendarObjects = data.calendars.map(item => item);
          let calendarObjects = this.groups.flatMap(team => team.calendars || []);
          let missingTeams = this.groups.filter(team => !team.calendars || team.calendars.length === 0);
          if (missingTeams.length > 0) {
            if (this.tries === 0) {
              this.tries = this.tries + 1
              this.createCalendarsForMissingGroups(missingTeams).subscribe((newCalendars) => {
                this.allCalendars = [...calendarObjects, ...newCalendars];
                this.getGroupsWithCalendars();
              });
            }
          } else {
            this.allCalendars = [...calendarObjects];
            this.refreshCalendars().subscribe();
            this.workerteamsLoading = false;
            this.selectedWorkerteam = 'filterOff'
            this.changeWorkerteamFilter(Event)
          }
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  getWorkerCountForSelectedCalendar(selectedCalendarId: number): number {
    // Traverse the groups array
    for (let group of this.groups) {
      // Check if the group has calendars
      if (group.calendars) {
        // Traverse the calendars array within the group
        for (let calendar of group.calendars) {
          // Check if this calendar matches the selectedCalendarId
          if (calendar.id === selectedCalendarId) {
            // Return the worker_count of this group
            return group.worker_count;
          }
        }
      }
    }

    // If the function didn't return by now, then the selectedCalendarId wasn't found
    // Return 0 or whatever default value you deem appropriate
    return 0;
  }


  createCalendarsForMissingGroups(missingTeams: any[]): Observable<any> {
    // If no teams are missing, return a resolved observable
    if (missingTeams.length === 0) {
      return of(null);
    }

    // Create observables for creating calendars and then linking them
    const creationAndLinkingObservables = missingTeams.map(team => {
      const calendar = {
        name: team.name + ' Calendar',
        startdate: this.formatDate(this.globalStartDate),
        enddate: this.formatDate(new Date())
      };

      return this.calendars.createCalendar(calendar, this.selectedStatus.id).pipe(
        switchMap((createdCalendar) => {
          const calendarId = createdCalendar;
          return this.calendars.linkToWorkerteam(team.id, calendarId);
        })
      );
    });

    // Combine observables to execute in parallel and wait for all to complete
    return forkJoin(creationAndLinkingObservables).pipe(
      tap(() => {
      })
    );
  }

  getFirstDayOfWeekForWeekNumber(weekNr: number): Date {
    const jan1 = new Date(new Date().getFullYear(), 0, 1);
    const daysToAdd = (weekNr - 1) * 7 - jan1.getUTCDay() + 1; // +1 to start from Monday
    jan1.setDate(jan1.getDate() + daysToAdd);
    return jan1;
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  getRowNumbers(projectId): Observable<RowNumber[]> {
    return this.workOrderService.getRowNumbers(projectId).pipe(
      tap((rowNumbers: RowNumber[]) => {
        this.rowNumbers = rowNumbers;
      })
    );
  }

  getWorkOrders(limit: number | null, type: number): Observable<WorkOrder[]> {
    return this.workOrderService.getWorkOrders(limit, type).pipe(
      tap((workOrders: WorkOrder[]) => {
        this.originalWorkOrders = this.workOrders = workOrders
      })
    );
  }


  getInverters(limit: number | null, type: number): Observable<WorkOrder[]> {
    return this.workOrderService.getWorkOrders(limit, type).pipe(
      tap((inverters: WorkOrder[]) => {
        this.originalInverters = this.inverters = inverters
      })
    );
  }


  updateCalendarsDataArray() {
    this.originalCalendarsDataArray = [];
    for (const calendar of Object.values<CalendarInfo>(this.calendarDataByCalendarId)) {
      this.originalCalendarsDataArray.push(calendar);
    }
    const firstCalendarDays = this.calendarDataByCalendarId[this.selectedCalendar].calendarDays;

    // Convert date strings to Date objects
    this.calendarDays = firstCalendarDays.map(dateString => new Date(dateString));

    this.changeWorkerteamFilter()
  }


  initMap() {
    const centerCoordinates = this.getCenterCoordinates();

    if (this.map) {
      return;
    }

    this.map = L.map('mapmap', { editable: true });

    this.map.zoomControl.setPosition('topright');

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 20,
      minZoom: 15,
      detectRetina: true,
    }).addTo(this.map);

    this.map.on('locationerror', () => {
      this.map.panTo({ lat: centerCoordinates.latitude, lon: centerCoordinates.longitude });
    });

    // Set the initial center of the map
    const initialCenter = L.latLng(centerCoordinates.latitude, centerCoordinates.longitude);
    this.map.setView(initialCenter, 16);


    let drawPluginOptions = {
      position: 'topright',
      draw: {
        polygon: {
          allowIntersection: false,
          drawError: {
            color: '#e1e100',
            message: '<strong>Oh snap!<strong> you can\'t draw that!'
          },
          shapeOptions: {
            color: '#97009c'
          }
        },
        // disable toolbar item by setting it to false
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },

      edit: {
        featureGroup: this.editableLayers, //REQUIRED!!
        remove: false,
        edit: false
      }

    };

    this.drawControl = new L.Control.Draw(drawPluginOptions);

    this.canvasRenderer = L.canvas(); // Create a canvas renderer

    // Notify that the map is ready
    this.mapReady.next();
    this.addZoomListener();
  }

  getCenterCoordinates(): { latitude: number, longitude: number } {
    switch (this.customerId) {
      case 1:
        return {
          latitude: 60.18208069645202,
          longitude: 23.97548906518469
        };
      case 2:
        return {
          latitude: 60.03611032213076,
          longitude: 18.55047482522265
        };
      default:
        return {
          latitude: 0, // Default latitude
          longitude: 0 // Default longitude
        };
    }
  }

  cancelDrawing() {
    this.drawEnabled.disable()
    this.showCancelCreate = false
  }

  clearWorkOrderMarkers(): void {
    this.workOrderMarkers.forEach(marker => marker.remove());
    this.workOrderMarkers = new Map(); // Reset the work order markers array
  }


  /**
   * Function that is used to determine if a point resides inside given polygon
   * @param point location of point in map in coordinates
   * @param vs polygon area that we use to check if it has point inside it
   * @returns boolean value true/false depending is the point inside polygon
   */
  inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

    let x = point[0], y = point[1];

    let inside = false;

    for (let i = 0, j = vs[0].length - 1; i < vs[0].length; j = i++) {
      let xi = vs[0][i].lat, yi = vs[0][i].lng;
      let xj = vs[0][j].lat, yj = vs[0][j].lng;

      let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  };

  convertMinutesToHoursAndMinutes(totalMinutes: number): { hours: number, minutes: number } {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  }


  /**
   * Sets a calendars data by id to calendarData.
   * Sets teamId to be correct for weekly-planning-teams
   * @param id Calendar ID
   *
   * @edit 2.12.2022
   * Added api-call to get teams efficiency.
   * Added completeCalendarById function call.
   * @param value Boolean value, depends do we reset calendar or not
   * @author Jesse Lindholm
   */
  getCalendarById(id: number, value: boolean, newSlotId): Observable<any> {
    return this.calendars.getCalendarById(id, this.selectedStatus.id).pipe(
      takeUntil(this.componentDestroyed$),
      tap(data => {
        if (data.teams.length > 0) {
          this.teamsService.getTeamById(data.teams[0]).subscribe(
            teamData => {
              data.workdayLength = this.convertMinutesToHoursAndMinutes(teamData.workday_length)
              const calendarInfo = {
                calendarData: data,
                originalCalendarData: JSON.parse(JSON.stringify(data)),
                slotRows: []
              };
      
              this.calendarDataByCalendarId[id] = calendarInfo;
              this.setupCalendar(id, data.startdate, data.slots);
            }
          )
        } else {
          data.workdayLength = {hours: 0, minutes: 0}
          const calendarInfo = {
            calendarData: data,
            originalCalendarData: JSON.parse(JSON.stringify(data)),
            slotRows: []
          };
  
          this.calendarDataByCalendarId[id] = calendarInfo;
          this.setupCalendar(id, data.startdate, data.slots);
        }
        
      })
    );
  }

  setupCalendar(calendarId: number, startDate, slots) {

    const calendarInfo = this.calendarDataByCalendarId[calendarId];

    calendarInfo.slotRows = [];

    let start = new Date(this.globalStartDate);

    //check if startdate is a monday
    calendarInfo.firstDay = this.getFirstDayOfWeek(start);
    calendarInfo.lastDay = this.getLastDayOfWeek(start);

    let allDays = Array();

    while (start <= calendarInfo.lastDay) {
      let x = new Date(start.getTime());
      allDays.push(this.getDateInYYYYMMDD(x));
      start.setDate(start.getDate() + 1);
    }
    // calendarDays holds all the days that contain a slot.
    calendarInfo.calendarDays = allDays;

    for (let i = 0; i < allDays.length; i++) {
      const element = allDays[i];
      let dateString = element;

      let rowObject = Array();

      const currentSlots = this.getAllSlotsForCurrentDate(slots, dateString);
      if (currentSlots.length === 0) {
        const emptySlot = {
          id: null,
          date: dateString,
          starttime: `${element} 08:00:00`,
          max_default: 4,
          workOrders: []
        };
        rowObject.push(emptySlot);
      } else {
        for (const slot in currentSlots) {
          
          const test1 = Number(slot);
          const slotToPush = currentSlots[test1];
          rowObject.push(slotToPush);
        }
      }

      calendarInfo.slotRows.push(rowObject[0]);
    }
    this.selectedCalendar = calendarId;
    if (this.mainSelection === 0) this.drawWorkOrders()
    else if (this.mainSelection === 1 ) this.drawInverters()
    this.updateCalendarsDataArray();
  }

  getAllSlotsForCurrentDate(slots: { [key: number]: Slot }, currentDate: string): Slot[] {

    const slotArray = Object.values(slots);

    return slotArray.filter((slot: Slot) => {
      const slotDateString = this.getDateFromStartTime(slot.starttime);
      return slotDateString === currentDate;
    });
  }

  getTeamNameOfCalendar(calendarData: any): string | null {
    if (calendarData.teams && calendarData.teams.length > 0) {
      const teamId = calendarData.teams[0];
      const team = this.groups.find(group => group.id === teamId);
      return team ? team.name : null;
    }
    return null;
  }


  getDateFromStartTime(startTime: string): string {
    return startTime.split(' ')[0];
  }

  getDateInYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  emptySlot(slotId, calendarId) {
    this.selectSlotMode = false;
    const slot = this.findSlotById(slotId, calendarId);

    if (slot) {
      this.calendars.removeSlot(slotId).subscribe(
        () => {
          this.refreshCalendars().subscribe();
        },
        error => {
          console.error("Error removing slot:", error);
        }
      );
    }
  }

  /**
   * Returns the name of given date in short form.
   * @param date
   * @returns
   *
   * Update: Configured to return local version day name.
   * Defaults to English version
   */
  getDayName(dateString: string): string {
    const date = new Date(dateString);
    const language = this.translateService.currentLang;
    if (language === 'en') return date.toLocaleDateString('en-GB', { weekday: 'short' });
    else if (language === 'de') return date.toLocaleDateString('de-DE', { weekday: 'short' });
    else return date.toLocaleDateString('en-GB', { weekday: 'short' });
  }

  getDateFromString(dateString: string): Date {
    return new Date(dateString);
  }

  getDatePart(dateString: string): number {
    return this.getDateFromString(dateString).getDate();
  }

  getMonthPart(dateString: string): number {
    return this.getDateFromString(dateString).getMonth() + 1;
  }

  /**
   * Returns hours and minutes of startTime and endTime of a slot as string to render in calendar.
   *
   * example return: '07:00 - 10:00'
   * @param startString
   * @param endString
   * @returns
   */
  getTime(startString, endString) {

    if (!startString && !endString) return ''

    startString = new Date(startString)

    endString = new Date(endString)

    let startHours = startString.getHours()
    let startMinutes = startString.getMinutes()
    let endHours = endString.getHours()
    let endMinutes = endString.getMinutes()

    if (startHours < 10) startHours = '0' + startHours;
    if (startMinutes < 10) startMinutes = '0' + startMinutes;
    if (endHours < 10) endHours = '0' + endHours;
    if (endMinutes < 10) endMinutes = '0' + endMinutes;

    let startTime = startHours + ':' + startMinutes

    let endTime = endHours + ':' + endMinutes

    let totalTime = startTime + ' - ' + endTime

    return totalTime

  }


  setProgress(progress) {

    let color = "#FF9F0A"
    if (progress >= 90 && progress <= 100) {
      color = "#10A231"
    } else if (progress > 100) {
      color = "#0058FF"
    }

    let styles = {
      'width': progress + '%',
      'background-color': color
    };

    return styles
  }


  selectSlot(id, date, calendarId) {

    if (!this.selectSlotMode) return;
    if (this.loading) return;
    this.loading = true
    this.selectedCalendar = calendarId;
    this.invertersDrawn = false
    this.workordersDrawn = false

    if (id == null) {
      const convertedDate = date.split("/").reverse().join("-");
      const defaultSlotData = this.prepareNewSlotData(convertedDate, calendarId);
      this.handleNewSlotCreation(defaultSlotData, this.selectedMarkers, calendarId);
    } else {
      this.addWorkOrdersToSlot(id, this.selectedMarkers, calendarId);
    }

  }

  prepareNewSlotData(date: string, calendar) {

    // Convert the object to an array of objects
    const arr = Object.entries(this.defaultGroupSizes).map(([key, value]) => ({ id: parseInt(key), size: value }));

    // Find the group size with the matching id
    const foundGroupSize = arr.find(groupsize => groupsize.id === this.selectedStatus.id);

    // Check if a matching group size is found, otherwise set default
    let defaultNumberOfGroupMembersForStatus: any = 1;
    if (foundGroupSize) {
      defaultNumberOfGroupMembersForStatus = foundGroupSize.size
    }

    const currentGroupsMembersForStatus = this.getWorkerCountForSelectedCalendar(calendar);
    let multiplier = currentGroupsMembersForStatus / defaultNumberOfGroupMembersForStatus;

    //account for the situation when the multiplier becomes 0 when there are no currentGroupMembers for the status
    if (multiplier == 0) {
      alert("There are no workers in the team, setting default group size.");
      multiplier = 1
    }
    return {
      date: date,
      timeStart: `${date} 08:00`,
      timeEnd: `${date} 16:00`,
      duration: 480,
      slot_max: 100 * multiplier,
      disabled: false,
      description: ''
    };
  }

  handleNewSlotCreation(slotData, workOrdersToAdd, calendar) {
    this.createSlot(slotData, calendar).subscribe((id) => {
      let workOrdersForThisSlot: any[] = [];

      if (this.addToSingleSlot) {
        workOrdersForThisSlot = workOrdersToAdd;
        workOrdersToAdd = [];
      } else {
        let workorderLength = this.defaultTimeEstimates.find(estimate => estimate.id === this.selectedStatus.id)?.estimate ?? 0
        if (!workorderLength) {
          alert("No workorderLength");
          return;
        }
        let availableTime = slotData.duration / 100 * slotData.slot_max
        if (workorderLength > availableTime) {
          alert("The time estimate for the workorder is longer than the working day");
          return;
        }
        let fit = Math.floor(availableTime / workorderLength)
        if (workOrdersToAdd.length >= fit) {
          workOrdersForThisSlot = workOrdersToAdd.slice(0, fit);
          workOrdersToAdd = workOrdersToAdd.slice(fit);
        } else {
          workOrdersForThisSlot = workOrdersToAdd;
          workOrdersToAdd = [];
        }
      }

      workOrdersForThisSlot = this.handleWorkOrderSchedulingIssues(workOrdersForThisSlot, slotData.date);
      const workorderIds = workOrdersForThisSlot.map(wo => wo.id)
      this.calendars.updateCalendarSlotsMass(id, workorderIds, 'add', this.selectedStatus).subscribe({
        next: () => {
          for (var i = 0; i < workorderIds.length; i++) {
            let originalItem = this.originalWorkOrders.find(item => item.id === workorderIds[i])
            if (originalItem) {
              // If moved, remove all links from same status
              originalItem.slots = originalItem.slots.filter(slot => slot.status_id !== this.selectedStatus.id);
              const calendarTeam = this.getCalendarTeamWithCalendarId(calendar);
              // Add new link
              originalItem.slots.push({ "id": id, "status_id": this.selectedStatus.id, "starttime": slotData.timeStart, "team": calendarTeam })
            }
          }

          if (workOrdersToAdd.length > 0) {
            // jos workordereitä jää yli, hae seuraavan päivän slotti (tai tee se)
            // kutsu funktiota seuraavan päivän tiedoilla
            const nextDate = new Date(slotData.date);
            // nextDate.setDate(nextDate.getDate() + 1);
            // Get todays date, add one day, then if original date is friday, add two more, and if it is saturday add one more
            nextDate.setDate(nextDate.getDate() + 1 + (nextDate.getDay() === 5 ? 2 : (nextDate.getDay() === 6 ? 1 : 0)));

            let nextSlot = this.findSlotByDate(nextDate, calendar);
            if (!nextSlot || typeof nextSlot.id !== 'number') {
              // If slot does not exist, create it
              const slotData = this.prepareNewSlotData(nextDate.toISOString().slice(0, 10), calendar);
              this.handleNewSlotCreation(slotData, workOrdersToAdd, calendar);
            } else {
              // If slot exists, add work orders to it
              this.addWorkOrdersToSlot(nextSlot.id, workOrdersToAdd, calendar);
            }
          } else {
            //this.changeFilter(Event)
            this.refreshCalendars().subscribe();
            this.deselectAllWorkOrders();
            this.saveCalendarSimple(calendar);
            this.loading = false
          }
        }
      })
    })
  }

  getCalendarTeamWithCalendarId(calendarId): any {
    // Check if the calendarId exists in the calendarDataByCalendarId
    if (this.calendarDataByCalendarId && this.calendarDataByCalendarId[calendarId]) {
      // Access the team info using the provided data structure
      const calendarTeam = this.calendarDataByCalendarId[calendarId].calendarData.teams[0];
      return calendarTeam;
    } else {
      // Handle the case where the calendarId does not exist
      console.error(`No calendar found with id: ${calendarId}`);
      return null;
    }
  }


  handleWorkOrderSchedulingIssues(workOrders: any[], slotDate: string): any[] {
    // we want to filter out the workorders that are DONE
    const doneWorkOrders: any[] = workOrders.filter(wo => wo.status_id >= this.selectedStatus.id);


    // Notify the user about the work orders that are done and filtered out
    if (doneWorkOrders.length && this.selectedStatus.state >= 2) {
      workOrders = workOrders.filter(wo => wo.state < this.selectedStatus.state);
      alert(`The following work orders are done and won't be added: ${doneWorkOrders.map(wo => wo.id).join(', ')}`);
    }

    if (this.selectedStatus.state <= 2) {
      return workOrders; // No checks needed, return the original workOrders array
    }



    const workOrdersWithSchedulingIssues: any[] = [];
    const workOrdersInterrupted: any[] = [];
    const previousPhase = this.selectedStatus.state - 1;

    workOrders.forEach((workOrder: any) => {
      let isPreviousPhaseScheduledBefore = false;
      let hasPreviousPhaseSlot = false;

      workOrder.slots.forEach(slot => {
        if (slot.status_id == previousPhase) {
          hasPreviousPhaseSlot = true;
          if (slot.starttime > slotDate) {
            isPreviousPhaseScheduledBefore = true;
          }
        }
      });

      if (!hasPreviousPhaseSlot || isPreviousPhaseScheduledBefore) {
        workOrdersWithSchedulingIssues.push(workOrder);
      }
      if (workOrder.interrupted == 1) {
        workOrdersInterrupted.push(workOrder);
      }
    });

    if (workOrdersWithSchedulingIssues.length || workOrdersInterrupted.length) {
      let confirmText = "The following work orders have issues:";

      if (workOrdersWithSchedulingIssues.length) {
        confirmText += `\n\nThe previous phase of the workorder has not been scheduled, do you still want to continue?: ${workOrdersWithSchedulingIssues.map(wo => wo.id).join(', ')}`;
      }

      if (workOrdersInterrupted.length) {
        confirmText += `\n\nInterrupted: ${workOrdersInterrupted.map(wo => wo.id).join(', ')}`;
      }

      confirmText += "\n\nDo you want to continue scheduling them?";

      const userResponse = window.confirm(confirmText);

      if (!userResponse) {
        // Remove problematic workOrders from the original workOrders array before returning
        const problematicOrders = workOrdersWithSchedulingIssues.concat(workOrdersInterrupted);
        return workOrders.filter(wo => !problematicOrders.includes(wo));
      }
    }

    return workOrders;
  }


  getNextDateFromString(dateString: string): string {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0]; // Format it to 'YYYY-MM-DD'
  }


  isTargetSlotToReplace(slot, newSlotDate) {
    if (slot.date) {
      return slot.date === newSlotDate;
    } else {
      const slotDate = new Date(slot.starttime).toLocaleDateString();
      return slotDate === newSlotDate;
    }
  }

  addWorkOrdersToSlot(slotId, selectedWorkOrders, calendar) {
    let slot = this.findSlotById(slotId, calendar);
    slot.workorders = slot.workorders || [];

    if (this.addToSingleSlot) {
      const filteredWorkOrdersForThisSlot = this.handleWorkOrderSchedulingIssues(selectedWorkOrders, slot.starttime);
      // slot.workorders.push(...filteredWorkOrdersForThisSlot);
      this.updateSlotRow(slot, filteredWorkOrdersForThisSlot)
      this.deselectAllWorkOrders();
      this.saveCalendarSimple(calendar);
      this.loading = false
    } else {
      let workorderLength = this.defaultTimeEstimates.find(estimate => estimate.id === this.selectedStatus.id)?.estimate ?? 0
      if (!workorderLength) {
        alert("No workorderLength");
        return;
      }
      const currentDuration = (slot.duration / 100 * slot.slot_max);
      let availableTime = (slot.duration / 100 * slot.slot_max) - slot.fill
      if (workorderLength > currentDuration) {
        alert("The time estimate for the workorder is longer than the working day");
        return;
      }
      let fit = Math.floor(availableTime / workorderLength)

      const filteredWorkOrdersForThisSlot = this.handleWorkOrderSchedulingIssues(selectedWorkOrders, slot.starttime);

      if (filteredWorkOrdersForThisSlot.length > fit) {
        let workOrdersForThisSlot = filteredWorkOrdersForThisSlot.slice(0, fit);
        // slot.workorders.push(...workOrdersForThisSlot);
        this.updateSlotRow(slot, workOrdersForThisSlot)

        // If after filling all current slots we still have work orders left
        let workOrdersToAdd = filteredWorkOrdersForThisSlot.slice(fit);
        // jos workordereitä jää yli, hae seuraavan päivän slotti (tai tee se)
        // kutsu funktiota seuraavan päivän tiedoilla
        //const currentDate = new Date(slot.starttime.slice(0, 10));
        //const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

        const nextDate = new Date(slot.starttime.slice(0, 10));
        nextDate.setDate(nextDate.getDate() + 1 + (nextDate.getDay() === 5 ? 2 : (nextDate.getDay() === 6 ? 1 : 0)));
        let nextSlot = this.findSlotByDate(nextDate, calendar);
        if (!nextSlot || typeof nextSlot.id !== 'number') {
          // If slot does not exist, create it
          const slotData = this.prepareNewSlotData(nextDate.toISOString().slice(0, 10), calendar);
          this.handleNewSlotCreation(slotData, workOrdersToAdd, calendar);
        } else {
          // If slot exists, add work orders to it
          this.addWorkOrdersToSlot(nextSlot.id, workOrdersToAdd, calendar);
        }

      } else {
        // slot.workorders.push(...filteredWorkOrdersForThisSlot);
        this.updateSlotRow(slot, filteredWorkOrdersForThisSlot)
        this.deselectAllWorkOrders();
        this.saveCalendarSimple(calendar);
        this.loading = false
      }

    }

  }

  updateSlotRow(slot, workorders) {
    let copiedSlot = JSON.parse(JSON.stringify(slot));
    copiedSlot.workorders = copiedSlot.workorders || [];
    copiedSlot.workorders.push(...workorders);
    this.otherSlots.push(copiedSlot)
  }

  findSlotByDate(targetDate: Date, calendar): any {
    const targetDateString = targetDate.toISOString().slice(0, 10);
    //const slotRows = this.calendarDataByCalendarId[calendar].slotRows;
    const originalCalendarslots = this.calendarDataByCalendarId[calendar].originalCalendarData.slots;

    // for (let slot of slotRows) {
    //   if (slot.starttime && slot.starttime.slice(0, 10) === targetDateString) {
    //     return slot;
    //   }
    // }
    for (let key in originalCalendarslots) {
      if (originalCalendarslots.hasOwnProperty(key)) {
        let slot = originalCalendarslots[key];
        if (slot.starttime && slot.starttime.slice(0, 10) === targetDateString) {
          return slot;
        }
      }
    }
    return null;
  }


  findSlotById(id, calendarId): any {
    //const slotRows = this.calendarDataByCalendarId[calendarId].slotRows;
    const originalCalendarslots = this.calendarDataByCalendarId[calendarId].originalCalendarData.slots;
    // for (const slot of slotRows) {
    //   if (slot && typeof slot === 'object' && slot.id === id) {
    //     return slot;
    //   }
    // }
    for (let key in originalCalendarslots) {
      if (originalCalendarslots.hasOwnProperty(key)) {
        let slot = originalCalendarslots[key];
        if (slot.id === id) {
          return slot;
        }
      }
    }

    return null;
  }

  createSlot(slot, calendarId) {

    const starttimeFormatted = this.convertDateFormat(slot.timeStart);
    let minutes = 0
    for (let i = 0; i < this.calendarsDataArray.length; i++) {
      const element = this.calendarsDataArray[i];
      if (element.calendarData.id === calendarId) minutes = element.calendarData.workdayLength.hours * 60 + element.calendarData.workdayLength.minutes
    }
    let date = new Date(this.convertDateFormat(slot.timeStart))
    date.setMinutes(date.getMinutes() + minutes)
    const endtimeFormatted = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`

    const disabledValue = slot.disabled ? 1 : 0;
    const data = {
      calendar_id: calendarId,
      starttime: starttimeFormatted,
      endtime: endtimeFormatted,
      disabled: disabledValue,
      disabled_reason: slot.description,
      slot_max: slot.slot_max,
    };

    return this.calendars.createSlot(data, calendarId);
  }

  convertDateFormat(datetimeStr) {
    const [datePart, timePart] = datetimeStr.split(' ');

    // Split date into components
    const [year, month, day] = datePart.split('-');

    // Pad day and month with leading zeros if they are single digits
    const paddedDay = day.padStart(2, '0');
    const paddedMonth = month.padStart(2, '0');

    return `${year}-${paddedMonth}-${paddedDay} ${timePart.substring(0, 5)}`; // Ensuring the time is HH:MM
  }

  /**
   * Function for placing markers to slot.counts and to slot.markers for showing them in interface.
   * Go through layerGroups to find markers with id that matches slots id. Then we save iconSVG for further usage.
   * In html we use SVG icon to generate icon for interface. Slot.counts show primary markers position and when opened
   * we show slot.markers as more detailed version.
   * @param slot slot that we get from getCalendars function
   */
  putMarkersOnSlot(slot) {
    let markers = Array();
    let counts = Array()
    for (let i = 0; i < slot.workorders.length; i++) {
      let workorderId = slot.workorders[i].workorder_id
      markers.push({
        icon: this.getSvgIcon(slot.workorders[i].time_parameters),
        id: workorderId,
        old_device_id: slot.workorders[i].old_device_id,
        time: slot.workorders[i].time,
        checked: false
      })
    }

    let meters = markers
    meters.forEach((x) => {
      counts[x.icon] = (counts[x.icon] || 0) + 1
    });

    let countsArray = Array()


    for (const key in counts) {
      if (Object.prototype.hasOwnProperty.call(counts, key)) {
        const element = counts[key];

        countsArray.push({
          icon: key,
          count: element
        })
      }
    }


    // Put markers to correct places
    slot.counts = countsArray;
    slot.markers = markers;
  }

  getSvgIcon(time_parameters) {
    let timeEstim = []
    let msaTimeEstim = []
    let projectDefaultEstimates

    let markerShape = null
    let markerColor = null
    let markerDots = null

    // This loop goes through project default time estimates to get possible shapes/colors of the marker

    for (let i in timeEstim) {
      const categoryid = timeEstim[i]['categoryid']
      const estimateid = timeEstim[i]['estimateid']

      for (let x in projectDefaultEstimates) {
        const element = projectDefaultEstimates[x];


        if (element['categoryid'] == categoryid) {
          const highlighted = projectDefaultEstimates[x]['highlighted']
          if (projectDefaultEstimates[x]['estimates']) {
            for (let index = 0; index < projectDefaultEstimates[x]['estimates'].length; index++) {
              const timeEstimate = projectDefaultEstimates[x]['estimates'][index];

              if (timeEstimate['estimateid'] == estimateid) {
                if (highlighted == 'shape') {
                  markerShape = timeEstimate['attribute']

                } else if (highlighted == 'color') {
                  markerColor = timeEstimate['attribute']

                } else if (highlighted == 'dots') {
                  markerDots = timeEstimate['attribute']
                }
              }
            }
          }
        }
      }
    }

    // This  goes through msa time estimates to get possible shapes/colors of the marker, and overwrites any shapes and colors that we set above
    let svgString = this.mapService.getGeneratedSvg(markerShape, markerColor, markerDots)
    // Gets our marker icon from getGeneratedSvg method, returns default icon if null values are passed
    return svgString
  }


  /**
   * Called by week change arrow, handles change to next week
   */
  nextWeek() {

    // Calculate the nextMonday and set it to this.globalStartDate
    const nextMonday = this.getFirstDayOfNextWeek(this.globalStartDate);
    this.globalStartDate = nextMonday;
    this.globalLastDate = this.getLastDayOfWeek(nextMonday);

    // Update the week number once
    this.setWeekFromDate(nextMonday);

    for (const calendarId in this.calendarDataByCalendarId) {
      if (this.calendarDataByCalendarId.hasOwnProperty(calendarId)) {
        const calendarInfo = this.calendarDataByCalendarId[calendarId];

        this.setupCalendar(Number(calendarId), nextMonday, calendarInfo.calendarData.slots);
      }
    }
  }

  /**
   * Called by week change arrow, handles change to previous week
   */
  lastWeek() {

    // Calculate the lastMonday and set it to this.firstDay
    const lastMonday = this.getFirstDayOfLastWeek(this.globalStartDate);
    this.globalStartDate = lastMonday;
    const nextLastDay = this.getLastDayOfWeek(lastMonday);
    this.globalLastDate = nextLastDay;

    // Update the week number once
    this.setWeekFromDate(lastMonday);


    for (const calendarId in this.calendarDataByCalendarId) {
      if (this.calendarDataByCalendarId.hasOwnProperty(calendarId)) {
        const calendarInfo = this.calendarDataByCalendarId[calendarId];

        this.setupCalendar(Number(calendarId), lastMonday, calendarInfo.calendarData.slots);
      }
    }
  }


  /**
   * Gets first day of current week
   * @param d
   * @returns
   */
  getFirstDayOfWeek(d) {
    // clone date object, so we don't mutate it
    const date = new Date(d);
    const day = date.getDay();

    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }

  /**
   * Gets last day of current week
   * @param d
   * @returns
   */
  getLastDayOfWeek(d) {

    // clone date object, so we don't mutate it
    const date = new Date(d)
    const day = date.getDay() // get day of week

    const diff = date.getDate() - (day - 1) + 6;

    return new Date(date.setDate(diff));

  }

  /**
   * Sets the weeknumber from given date
   * @param d
   */
  setWeekFromDate(d: Date) {
    // Get January 4th of the year of the given date 'd'
    const jan4 = new Date(d.getFullYear(), 0, 4);

    // Calculate the number of days passed since January 4th
    const daysDifference = (d.getTime() - jan4.getTime()) / (24 * 60 * 60 * 1000);

    // Find the day of week for January 4th (0 = Sunday, 1 = Monday, ...)
    const dayOfWeek = jan4.getDay() || 7;

    // Calculate which day of the week the year started on
    const dayOfYearStarted = jan4.getDay() || 7;

    // Calculate the week number
    this.weekNr = Math.ceil((daysDifference + dayOfYearStarted) / 7);
  }

  /**
   * Returns first day of last week
   * @param d
   * @returns
   */
  getFirstDayOfLastWeek(d) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7);
  }

  /**
   * Returns first day of next week
   * @param d
   * @returns
   */
  getFirstDayOfNextWeek(d) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() + 7);
  }

  async saveCalendarSimple(calendar) {
    const selectedCalendar = this.calendarDataByCalendarId[calendar];

    if (!selectedCalendar) {
      return;
    }

    for (const slot of this.otherSlots) {
      if (slot.id) {
        const originalSlotData = selectedCalendar.originalCalendarData.slots[slot.id];

        const currentWorkOrders = slot.workorders || [];
        const originalWorkOrders = originalSlotData.workorders || [];
        const newWorkOrders = currentWorkOrders.filter(
          wo => !originalWorkOrders.some(origWo => origWo.id === wo.id)
      );
       const workorders = newWorkOrders.map(wo => wo.id)
        this.calendars.updateCalendarSlotsMass(slot.id, workorders, 'add', this.selectedStatus).subscribe({
          next: () => {
            for (let i = 0; i < newWorkOrders.length; i++) {
              const element = newWorkOrders[i];
              let originalItem = this.originalWorkOrders.find(item => item.id === element.id)
              if (originalItem) {
                // If moved, remove all links from same status
                originalItem.slots = originalItem.slots.filter(slot => slot.status_id !== this.selectedStatus.id);
                const calendarTeam = this.getCalendarTeamWithCalendarId(calendar);
                // Add new link
                originalItem.slots.push({ "id": slot.id, "status_id": this.selectedStatus.id, "starttime": slot.starttime, "team": calendarTeam })
              }
            }
            //this.changeFilter(Event)
            this.refreshCalendars().subscribe();
          }
        })
      }
    }
  }


  private executeApiCalls(observables: Observable<any>[], onSuccess: () => void, onError: (error: any) => void): void {
    if (!observables.length) {
      onSuccess();
      return;
    }

    forkJoin(observables).subscribe(onSuccess, onError);
  }


  ngAfterViewInit() {
    this.unsubscribeClickOutside = this.renderer.listen('document', 'click', (event) => {
      this.detailsRefs.forEach(detailsRef => {
        if (!detailsRef.nativeElement.contains(event.target) && detailsRef.nativeElement.open) {
          detailsRef.nativeElement.open = false;
        }
      });
    });
  }


  selectWorkOrder(workOrder: WorkOrder): void {
    this.selectedMarkers.push(workOrder);
    this.selectSlotMode = true
  }


  deselectWorkOrder(workOrder: WorkOrder): void {
    this.selectedMarkers = this.selectedMarkers.filter(
      wo => wo.id !== workOrder.id
    );

    const marker = this.findMarkerByWorkOrderId(workOrder.id);
    if (marker) {
      // Update the marker style to the original when deselected
      this.updateMarkerStyle(marker, workOrder.id, 'grey', 1);
    }

    if (!this.hasSelectedWorkOrders()) {
      this.selectSlotMode = false;
    }
  }


  findMarkerByWorkOrderId(workOrderId: number): L.Marker | undefined {
    return this.workOrderMarkers.get(workOrderId);
  }

  deselectAllWorkOrders(): void {
    this.selectedMarkers = [];
    this.selectSlotMode = false;
  }

  isSelected(workOrderOrInverter): boolean {
    return this.selectedMarkers.some(wo => wo.id === workOrderOrInverter.id);
  }

  hasSelectedWorkOrders(): boolean {
    return this.selectedMarkers.length > 0;
  }

  calculateFillPercentage(slot, defaultmax): number {
    let max = slot.slot_max != null ? slot.slot_max : defaultmax
    if (max == 0) {
      return 0
    }
    let fill = slot.fill / max * 100
    return fill
  }

  get selectedMarkersCount(): number {
    return this.selectedMarkers.length;
  }

  onStatusChange(status, saveState: boolean): void {
    this.calendarDataByCalendarId = [];
    this.selectedMarkers = [];
    this.calendarsDataArray = [];
    if (this.statuses.find(s => s.id === status.id)) this.selectedStatus = status

    if (saveState) {
      this.tries = 0
      this.workordersDrawn = false
      this.invertersDrawn = false
      localStorage.setItem('phaseState', JSON.stringify(this.selectedStatus));
    }

    this.getGroupsWithCalendars();
    this.initMap();
}

changeFilter(): void {
  this.workordersDrawn = false
  this.invertersDrawn = false
  const filterItems = (items: any[]) => {
      let filteredItems = items;

      if (this.selectedWorkerteam !== 'filterOff') {
          filteredItems = filteredItems.filter(item =>
              item.slots?.some(slot => slot.team === this.selectedWorkerteam)
          );
      }

      switch (this.selectedFilter) {
          case 'scheduled':
              filteredItems = filteredItems.filter(item =>
                  item.slots.some(slot => slot.status_id === this.selectedStatus.id)
              );
              break;
          case 'notScheduled':
              filteredItems = filteredItems.filter(item =>
                  !item.slots.some(slot => slot.status_id === this.selectedStatus.id)
              );
              break;
          case 'done':
              filteredItems = filteredItems.filter(item => {
                  const statusObj = this.statuses.find(obj => obj.id === item.status_id);
                  return statusObj && statusObj.state >= this.selectedStatus.state;
              });
              break;
          case 'interrupted':
              filteredItems = filteredItems.filter(item => item.interrupted);
              break;
      }

      return filteredItems;
  };

  if (this.mainSelection === 0) {
      this.workOrders = filterItems(this.originalWorkOrders);
      this.drawWorkOrders();
  } else if (this.mainSelection === 1){
      this.inverters = filterItems(this.originalInverters);
      this.drawInverters();
  }
}

  changeWorkerteamFilter(event: any | null = null): void {
    if (this.selectedWorkerteam == 'filterOff') {
      this.calendarsDataArray = this.originalCalendarsDataArray;
    } else {
      this.calendarsDataArray = this.originalCalendarsDataArray.filter(calendar => calendar.calendarData.teams.includes(this.selectedWorkerteam));
    }
    this.changeFilter()
  }

  changeMainSelect(value) {
    this.mainSelection = parseInt(value)
    this.tries = 0
    this.calendarDataByCalendarId = [];
    this.selectedMarkers = [];
    this.calendarsDataArray = [];
    this.originalCalendarsDataArray = []
    this.getStatuses(this.projectId).subscribe()
    this.clearMap()
    this.initMap();
    
  }

  clearMap() {
    if (this.inverterMarkers) this.inverterMarkers.forEach(e => e.remove())
    if (this.workOrderMarkers) this.workOrderMarkers.forEach(e => e.remove())
  }


  drawWorkOrders() {
    if (this.map) {
      // Remove any existing work order markers from the map
      if (!this.workordersDrawn){

        if (this.workOrderMarkers) {
          this.workOrderMarkers.forEach(marker => marker.remove());
        } 
        this.workordersDrawn = true
        this.invertersDrawn = false
        this.workOrderMarkers = new Map(); // Reset the work order markers array

        const currentZoomLevel = this.map.getZoom(); // Get current zoom level
        const markerSize = this.getMarkerSize(currentZoomLevel); // Set marker size based on zoom level

        this.workOrders.forEach((workorder) => {
          let color = this.selectedStatus?.color ?? '#111111';
          let opacity = 1
          const lat = Number(workorder.latitude);
          const lng = Number(workorder.longitude);

          if (!isNaN(lat) && !isNaN(lng)) {
            const marker = L.customRectangleMarker([lat, lng], {
              renderer: this.canvasRenderer,
              size: markerSize, // Size for rectangle
              fillColor: color,
              color: color,
              border: color,
              opacity: opacity,
              fillOpacity: opacity,
              weight: 1, // Ensure the border weight is specified
              isSelected: false // Initialize as not selected
            }).addTo(this.map);
            // Click event: toggle selection
            marker
              .bindTooltip(this.customerId === 2 ? workorder.transformer + '-' + workorder.inverter + '-' + workorder.circuit : '' + workorder.id)
              .on('click', () => {
                if (this.isSelected(workorder)) {
                  this.deselectWorkOrder(workorder);
                  this.updateMarkerStyle(marker, color, opacity, false);
                } else {
                  this.selectWorkOrder(workorder);
                  this.updateMarkerStyle(marker, color, opacity, true);
                }
              });
            // Add the marker to the workOrderMarkers array
            this.workOrderMarkers.set(workorder.id, marker);
            
          } else {
            console.error(`Invalid coordinates for workorder id=${workorder.id}: lat=${lat}, lng=${lng}`);
          }
        
        }
        
      );
      //if (lat2 && lng2) this.map.panTo({lon: lng2, lat: lat2})
    }
    }
  }

  drawRowNumbersOnMap(): void {
    // Remove any existing row number markers from the map
    if (this.rowNumberMarkers) {
      this.rowNumberMarkers.forEach(marker => marker.remove());
    }
    this.rowNumberMarkers = new Map<number, L.Layer>(); // Reset the row number markers array

    const canvasRenderer = L.canvas(); // Create a canvas renderer

    this.rowNumbers.forEach((rowNumber) => {
      const lat = Number(rowNumber.position_x);
      const lng = Number(rowNumber.position_y);

      if (!isNaN(lat) && !isNaN(lng)) {
        // Create a custom text marker
        const marker = L.customTextMarker([lat, lng], {
          renderer: canvasRenderer,
          text: rowNumber.number.toString(),
          fontSize: 12, // Adjust font size as needed
          fontColor: 'black' // Adjust font color as needed
        }).addTo(this.map);

        // Add the marker to the rowNumberMarkers array
        this.rowNumberMarkers.set(rowNumber.id, marker);
      } else {
        console.error(`Invalid coordinates for row number id=${rowNumber.id}: lat=${lat}, lng=${lng}`);
      }
    });
  }



  createIcon(size: number, size2: number, color, opacity) {
    return L.divIcon({
      className: '',
      iconSize: [size, size2], // square size
      html: `<div style="width: 85%; height: 70%; background-color: ${color}; opacity: ${opacity};"></div>`
    });
  }

  updateMarkerStyle(marker, color, opacity, isSelected) {
    if (isSelected) {
      // Update the marker to be white with a black outline
      marker.setStyle({
        fillColor: 'white',
        color: 'black',
        fillOpacity: opacity,
        weight: 2 // Adjust the outline thickness as needed
      });
    } else {
      // Revert to the original style
      marker.setStyle({
        fillColor: color,
        color: color,
        fillOpacity: opacity,
        weight: 1 // Adjust the outline thickness as needed
      });
    }

    // Update the isSelected property
    marker.options.isSelected = isSelected;
  }

  addZoomListener() {
    this.map.on('zoomend', () => {
      const currentZoomLevel = this.map.getZoom();
      const markerSize = this.getMarkerSize(currentZoomLevel); // Set marker size based on zoom level
      const markerSize2 = this.getInverterSize(currentZoomLevel)

      if (this.mainSelection === 0) {
        this.workOrderMarkers.forEach(marker => {
          marker.options['size'] = markerSize
        })
      } else if (this.mainSelection === 1) {
        this.inverterMarkers.forEach(marker => {
          marker.options['size'] = markerSize2
        })
      }
    });
  }

  getMarkerSize(zoomLevel: number): [number, number] {
    if (this.customerId == 1) {
      switch (zoomLevel) {
        case 19:
          return [45, 45]
        case 18:
          return [20, 20];
        case 17:
          return [12, 12];
        case 16:
          return [5, 5];
        case 15:
          return [3.5, 3.5];
        default:
          return [60, 60];
      }
    } else {
      switch (zoomLevel) {
        case 19:
          return [60, 10]
        case 18:
          return [30, 5];
        case 17:
          return [15, 1.5];
        case 16:
          return [13, 0.5];
        case 15:
          return [6, 0.3];
        default:
          return [60, 10];
      }
    }
  }

  getInverterSize(zoomLevel: number) : [number, number] {
    switch (zoomLevel) {
      case 19:
        return [35, 35]
      case 18:
        return [15, 15];
      case 17:
        return [7, 7];
      case 16:
        return [1.5, 1.5];
      case 15:
        return [0.5, 0.5];
      default:
        return [60, 60];
    }
  }


  limitedWorkorders(workorders: WorkOrder[]): WorkOrder[] {
    const result: WorkOrder[] = [];
    if (workorders && workorders.length) {
      if (this.customerId !== 2) {
        for (let i = 0; i < workorders.length && i < 9; i++) {
          result.push(workorders[i]);
        }
      } else {
        for (let i = 0; i < workorders.length && i < 3; i++) {
          result.push(workorders[i]);
        }
      }
    }
    return result;
  }

  remainingWorkorders(workorders: WorkOrder[]): WorkOrder[] {
    const result: WorkOrder[] = [];
    if (workorders && workorders.length > 9 && this.customerId !== 2) {
      for (let i = 9; i < workorders.length; i++) {
        result.push(workorders[i]);
      }
    } else if (workorders && workorders.length > 3 && this.customerId === 2) {
      for (let i = 3; i < workorders.length; i++) {
        result.push(workorders[i]);
      }
    }
    return result;
  }

  toggleExpand(slotId: number) {
    if (this.expandedSlotId === slotId) {
      this.expandedSlotId = null; // collapse if it's already expanded
    } else {
      this.expandedSlotId = slotId; // expand the clicked slot
    }
  }

  setUpCustomRectangleCanvas() {
    L.Canvas.include({
      _updateCustomRectangle: function (layer) {
        const ctx = this._ctx;
        const p = layer._point;
        const size = layer.options.size;
        const halfWidth = size[0] / 2;
        const halfHeight = size[1] / 2;

        ctx.save();
        ctx.beginPath();
        ctx.rect(p.x - halfWidth, p.y - halfHeight, size[0], size[1]);
        ctx.fillStyle = layer.options.fillColor;
        ctx.fill();
        ctx.strokeStyle = layer.options.color;
        ctx.lineWidth = layer.options.weight;
        ctx.stroke();
        ctx.restore();
      }
    });

    L.CustomRectangleMarker = L.CircleMarker.extend({
      options: {
        size: [20, 10], // width, height
      },

      _updatePath: function () {
        this._renderer._updateCustomRectangle(this);
      }
    });

    L.customRectangleMarker = function (latlng, options) {
      return new L.CustomRectangleMarker(latlng, options);
    };
  }


  setUpCustomTextMarkerCanvas() {
    L.Canvas.include({
      _updateCustomText: function (layer) {
        const ctx = this._ctx;
        const p = layer._point;
        const text = layer.options.text;
        const fontSize = layer.options.fontSize || 12;
        const fontColor = layer.options.fontColor || 'black';

        ctx.save();
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = fontColor;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, p.x, p.y);
        ctx.restore();
      }
    });

    L.CustomTextMarker = L.CircleMarker.extend({
      options: {
        text: '',
        fontSize: 12,
        fontColor: 'black'
      },

      _updatePath: function () {
        this._renderer._updateCustomText(this);
      }
    });

    L.customTextMarker = function (latlng, options) {
      return new L.CustomTextMarker(latlng, options);
    };

  }

  drawInverters() {
    
    if (!this.invertersDrawn) {
      if (this.inverterMarkers) {
        this.inverterMarkers.forEach(marker => marker.remove());
      }
      this.invertersDrawn = true
      this.inverterMarkers = new Map();

      const currentZoomLevel = this.map.getZoom(); // Get current zoom level
      const markerSize = this.getInverterSize(currentZoomLevel); // Set marker size based on zoom level

      this.inverters.forEach((inverter) => {
        let color = 'red'
        if (this.selectedStatus) color = this.selectedStatus.color ?? 'red';
        let opacity = 1;
        const lat = Number(inverter.latitude);
        const lng = Number(inverter.longitude);
        if (!isNaN(lat) && !isNaN(lng)) {
          const marker = L.customRectangleMarker([lat, lng], {
            renderer: this.canvasRenderer,
            size: markerSize,
            fillColor: color,
            color: color,
            opacity: opacity,
            fillOpacity: opacity,
            weight: 1,
            isSelected: false
          }).addTo(this.map);
          // Click event: toggle selection
          marker
            .bindTooltip(this.customerId === 2 ? inverter.transformer + '-' + inverter.inverter : '' + inverter.id)
            .on('click', () => {
              // TODO isSelected works only with workorders currently
              if (this.isSelected(inverter)) {
                this.deselectInverter(inverter);
                this.updateMarkerStyle(marker, color, opacity, false);
              } else {
                this.selectInverter(inverter);
                this.updateMarkerStyle(marker, color, opacity, true);
              }
            });
          // Add the marker to the inverterMarkers array
          this.inverterMarkers.set(inverter.id, marker);
        } else {
          console.error(`Invalid coordinates for inverter id=${inverter.id}: lat=${lat}, lng=${lng}`);
        }
      });
      this.workordersDrawn = false
    }
  }

  isInverterSelected(inverter: Inverter): boolean {
    return false
  }

  selectInverter(inverter: WorkOrder) {
    this.selectedMarkers.push(inverter);
    this.selectSlotMode = true
  }

  deselectInverter(inverter: WorkOrder) {
    this.selectedMarkers = this.selectedMarkers.filter(
      i => i.id !== inverter.id
    );
  }
}

interface CalendarInfo {
  calendarData: any;
  originalCalendarData: any;
  slotRows: any[];
}

interface Slot {
  id: number;
  calendar_id: number;
  created: string;
  disabled: number;
  disabled_reason: string | null;
  duration: number;
  endtime: string;
  fill: number;
  modified: string | null;
  removed: string | null;
  slot_max: number;
  starttime: string;
  workorders: WorkOrder[];
}

export interface RowNumber {
  id: number;
  number: string;
  position_x: number;
  position_y: number;
  project_id: number;
  created: Date;
  modified: Date | null;
}

export interface Inverter {
  id: number;
  transformer: string;
  inverter: string;
  latitude: number;
  longitude: number;
  project_id: number;
  created: Date;
  modified: Date | null;
  removed: Date | null;
  status_id: number;
}

