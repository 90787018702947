import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { ReportsService } from '@modules/planner/services/reports/reports.service';
import { ActivatedRoute } from '@testing/activated-route.stub';
import { Subject, of, switchMap, takeUntil } from 'rxjs';
import { Params, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import { TranslateService } from '@ngx-translate/core';
import imageCompression from 'browser-image-compression';
import { DomSanitizer } from '@angular/platform-browser';
import { WorkOrder } from '@shared/models/work-order';
import { UserService } from '@shared/services/user/user.service';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { Status } from '@shared/models/status';


declare const L: any;
@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit, OnDestroy {

    // Barcode scanner


    clickedButtonId: number = 0
    clickedButton2Id: number = 2

    componentDestroyed$: Subject<boolean> = new Subject()

    newMeterNr: any
    newMeterReading: any
    installationTime: any
    installationDate: Date = new Date()
    installationDateToApi: any
    installHours: any
    installMinutes: any
    additionalInfo: string = ""
    switchedDate: any
    switchedTime: any
    dirtyTime = false
    timeUntouched = true
    newMeterOnSave = false;
    saveAdditional = false;
    secondTabDisabled = true;
    thirdTabDisabled = true;
    showOtherWorkType: boolean = false
    otherWorkType: string | null = null

    firstTabImgCheck = false
    secondTabImgCheck = false
    thirdTabImgCheck = false

    //danish stuff
    interruptCode: number | null = 0
    interruptReason: string | null = null
    notes: string | null = null

    // Indicates if the textarea is mandatory
    isMandatory: boolean = false;
    // Indicates if the textarea is enabled
    textareaEnabled: boolean = false;
    isOptional: boolean = false;

    imageMandatory: boolean = false; // Indicates if the image upload is mandatory
    imageOptional: boolean = false; // Indicates if the image upload is optional

    id: string  = ""
    accessTypeInfo = ''
    accessType: number | null = null
    story = ''
    placementText = ''

    images: Array<any> = [];
    formType = "create"
    reportId = 0
    statusId = 0
    reportType = 0
    loading = true

    workorderData: WorkOrder | null = null
    customerId: any = null
    workerteamId: number | null = null
    normalReport: boolean = true

    // Type 3 report
    pilingDone: boolean = false
    frameAssemblyDone: boolean | null = null
    panelInstallationDone: boolean | null = null
    type3Notes: string | null = null
    allStatuses: Status[] = []



    constructor(
        private workorderService: WorkOrderService,
        private reportService: ReportsService,
        private route: ActivatedRoute,
        private router: Router,
        private fieldReportingService: FieldReportingService,
        private translateService: TranslateService,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private contractorService: PlannerProjectService
    ) { }

    // Get meter id from url
    ngOnInit(): void {
        this.route.paramMap.subscribe(pmap => {
          this.id = pmap.get('id') ?? "";
          this.getCustomerId()
        });

        this.route.queryParams.subscribe(params => {
          if (params['rid']) {
            this.reportService.getReport(params['rid'])
              .pipe(
                takeUntil(this.componentDestroyed$),
                switchMap(data => {
                  if (this.id == data.workorder_id) {
                    this.formType = 'edit';
                    this.reportId = data.id;
                    this.reportType = parseInt(data.report_type, 10);
                    if (data.state !== 1) {
                        if (this.reportType === 1) {
                          this.notes = data.notes;
                          this.clickedButtonId = 1;
                          this.clickedButton2Id = 2;
                        } else if (this.reportType === 2) {
                          this.interruptReason = data.notes;
                          this.clickedButtonId = 2;
                          this.clickedButton2Id = 0;
                        } else if (this.reportType === 3) {
                          this.notes = data.notes;
                          this.clickedButtonId = 1;
                          this.clickedButton2Id = 1;
                        }
                    } else {
                        this.type3Notes = data.notes
                    }
                    return of(null)
                  } else {
                    return of(null); // Return an observable if condition doesn't match
                  }
                })
              )
              .subscribe(() => {
                this.loading = false;
              });
          } else this.loading = false

          if (params['wid']) {
            this.workerteamId = params['wid']
          }
        });

        this.getCustomerId();
      }

    ngOnDestroy() {
        this.componentDestroyed$.next(true)
        this.componentDestroyed$.complete()
    }

    getCustomerId() {
        this.userService.getUserInfo().subscribe({
            next: data => {
                this.customerId = data.customer_id
                if (this.customerId === 2) {
                    this.workorderService.getWorkOrder(parseInt(this.id)).subscribe({
                        next: data => {
                            this.workorderData = data
                            if (data.type === 3) {
                                this.contractorService.getStatuses().subscribe(
                                    data => this.allStatuses = data.message.filter(d => d.type === 3)
                                )
                                this.normalReport = false
                                if (data.state === 4) {
                                    this.panelInstallationDone = true
                                    this.frameAssemblyDone = true
                                    this.pilingDone = true
                                } else if (data.state === 3) {
                                    this.frameAssemblyDone = true
                                    this.pilingDone = true
                                } else if (data.state === 2) this.pilingDone = true
                            }
                        },
                        error: err => console.log(err)
                    })
                }
            }
        })
    }


    /**
     * 15.12.2022
     * Set limitations for image compression.
     * Then call imageCompression api from browser-image-compression library.
     * Use end result in images array.
     * @param event Allows to select file with catching event parameter
     * @param index What index of image we are using
     * @author Jesse Lindholm
     */
    async handleImageUpload(event) {
        const imageFile = event.target.files[0];
        if (!imageFile) return;

        const options = {
            maxSizeMB: 3,
            maxWidthOrHeight: 800,
            useWebWorker: true
        };

        try {
            await imageCompression(imageFile, options).then(output => {
                const imageObject = {
                    data: output,
                    img: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(output))
                };
                this.images.push(imageObject);
            });
        } catch (error) {
            // Handle the error as needed
        }

        event.target.value = '';  // Reset the input to ensure it triggers a change event next time even if the same image is selected
    }

    // Back to dashboard function that we use in first tab if user presses back button.
    // Set meter id to queryParams so we can access it in dashboard component directly from url
    backToDashboard() {
        // const queryParamsValue: Params = { meterId: this.id };
        this.router.navigate(['field-reporting/dashboard'], {
            // queryParams: queryParamsValue,
            // queryParamsHandling: 'merge'
        })
    }

    /**
     * Prepares formData from jsonObjects and sends it to backend
     *
     * @edit 14.09.2023
     * Added new logic to pictures
     * Now uses "images" array instead of single "img0data" and "img1data"
     * @author Victor Påwals
     */
    saveReport(formType) {
        this.loading = true
        const formData = new FormData();
        let jsonData:object = {};

        if (this.clickedButtonId == 1) {
            jsonData = {
                "report_type": 1,
                "notes": this.notes,
            };
            if(this.clickedButton2Id == 1) {
                jsonData["report_type"] = 3;
            }
        } else {
            jsonData = {
                "report_type": 2,
                "interrupted": 1,
                "notes": this.interruptReason,
                "interrupt_code": this.interruptCode
            };
        }

      jsonData["wid"] = this.workerteamId

      // Dynamically generate image data based on the available images.
      let imageData: ImageData[] = [];

      for (let i = 0; i < this.images.length; i++) {
          if (this.images[i] && this.images[i].data && this.images[i].data.name) {
              const imagePlacePrefix = (this.clickedButtonId === 1) ? 'reported_' :
                  (this.clickedButtonId === 2) ? 'interrupted_' : '';
              imageData.push({
                  "image_place": imagePlacePrefix + i,
                  "title": this.images[i].data.name,
                  "image_key": 'img' + i
              });
              formData.append('img' + i, this.images[i].data as any);
          }
      }

      if(formType == "edit") {
          jsonData["rid"] = this.reportId
      }
      formData.append('images', JSON.stringify(imageData) as any);
      formData.append('reportdata', JSON.stringify(jsonData) as any);
      //return
      this.fieldReportingService.reportWorkorder(this.id, formData, formType)
          .subscribe(
              result => {
                  if (result) {
                      Swal.fire({
                          title: this.translateService.instant('fieldReporting.shared.swal.success'),
                          text: this.translateService.instant('fieldReporting.report.swal.reportSuccessText'),
                          icon: 'success',
                          confirmButtonColor: '#3085d6',
                      }).then((result) => {
                          this.loading = false
                          if (result.isConfirmed || result.isDismissed) {
                              this.backToDashboard();
                          }
                      });
                  } else {
                      Swal.fire(
                          this.translateService.instant('fieldReporting.report.swal.error'),
                          this.translateService.instant('fieldReporting.shared.swal.tryAgain'),
                          'error'
                      );
                      this.loading = false
                  }
              }
          );
    }


    canSave(): boolean {
        if(this.loading) {
            return false
        }
        if (this.clickedButtonId == 0) {
            return false
        } else if (this.clickedButtonId == 1) {
            if (this.clickedButton2Id === 0) {
                return false
            } else {
                return true
            }
        } else {
            // First check: if no interruptCode is selected, you can't save.
            if (this.interruptCode === null) {
                return false;
            }

            // Check if comment is mandatory and is filled out
            const isCommentFilled = !this.isMandatory || (this.isMandatory && this.interruptReason && this.interruptReason.trim() !== '');

            // Check if image is mandatory and is uploaded
            const isImageUploaded = !this.imageMandatory || (this.imageMandatory && this.images && this.images[0].img);

            return isCommentFilled && isImageUploaded;
        }
    }

    saveReportType3() {
        const formData = new FormData()
        let jsonData: any = {}
        let statusId: number | null = null

        if (this.pilingDone && this.frameAssemblyDone && this.panelInstallationDone) {
            let id = this.allStatuses.find(status => status.state === 4)
            if (id) statusId = id.id
        }
        else if (this.pilingDone && this.frameAssemblyDone) {
            let id = this.allStatuses.find(status => status.state === 3)
            if (id) statusId = id.id
        }
        else if (this.pilingDone) {
            let id = this.allStatuses.find(status => status.state === 2)
            if (id) statusId = id.id
        }
        else {
          let id = this.allStatuses.find(status => status.state === 1)
          if (id) statusId = id.id
        }

        jsonData = {
            "report_type": 1,
            "notes": this.type3Notes,
        }

        if(this.formType == "edit") {
            jsonData["rid"] = this.reportId
            jsonData["new_status_id"] = statusId

        } else jsonData["next_status_id"] = statusId

        jsonData["wid"] = this.workerteamId
        formData.append('reportdata', JSON.stringify(jsonData) as any)

        let imageData: ImageData[] = [];

        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i] && this.images[i].data && this.images[i].data.name) {
            const imagePlacePrefix = (this.clickedButtonId === 1) ? 'reported_' :
              (this.clickedButtonId === 2) ? 'interrupted_' : '';
            imageData.push({
              "image_place": imagePlacePrefix + i,
              "title": this.images[i].data.name,
              "image_key": 'img' + i
            });
            formData.append('img' + i, this.images[i].data as any);
          }
        }
        formData.append('images', JSON.stringify(imageData) as any);

        this.fieldReportingService.reportWorkorderType3(this.id, formData, this.formType)
          .subscribe(
              result => {
                  if (result) {
                      Swal.fire({
                          title: this.translateService.instant('fieldReporting.shared.swal.success'),
                          text: this.translateService.instant('fieldReporting.report.swal.reportSuccessText'),
                          icon: 'success',
                          confirmButtonColor: '#3085d6',
                      }).then((result) => {
                          this.loading = false
                          if (result.isConfirmed || result.isDismissed) {
                              this.backToDashboard();
                          }
                      });
                  } else {
                      Swal.fire(
                          this.translateService.instant('fieldReporting.report.swal.error'),
                          this.translateService.instant('fieldReporting.shared.swal.tryAgain'),
                          'error'
                      );
                      this.loading = false
                  }
              }
          );
    }


    changeButtonId(buttonId: number) {
        if (buttonId !== this.clickedButtonId) this.clickedButtonId = buttonId
        else this.clickedButtonId = 0

        if(buttonId == 1) {
            this.imageMandatory = false
            this.imageOptional = true
        } else if (buttonId == 2) {
            this.checkInterruptCode()
        }
    }
    changeButton2Id(buttonId: number) {
        if (buttonId !== this.clickedButton2Id) this.clickedButton2Id = buttonId
        else this.clickedButton2Id = 0
    }

    checkInterruptCode() {
        // const mandatoryCodesForImage = ["02", "04", "06", "07", "08"];
        const mandatoryCodesForImage: string[] = [];
        // const mandatoryCodesForComment = ["02", "03", "04", "05", "06", "07", "08"];
        const mandatoryCodesForComment: string[] = [];

        if (this.interruptCode !== null) {
            const interruptCodeStr = this.interruptCode.toString();

            // For comment section
            if (mandatoryCodesForComment.includes(interruptCodeStr)) {
                this.isMandatory = true;
                this.isOptional = false;
                this.textareaEnabled = true;
            } else {
                this.isMandatory = false;
                this.isOptional = true;
                this.textareaEnabled = true;
            }

            // For image section
            if (mandatoryCodesForImage.includes(interruptCodeStr)) {
                this.imageMandatory = true;
                this.imageOptional = false;
            } else {
                this.imageMandatory = false;
                this.imageOptional = true;
            }
        } else {
            // Handle the case when interruptCode is null if needed
            this.isMandatory = false;
            this.isOptional = false;
            this.textareaEnabled = false;
            this.imageMandatory = false;
            this.imageOptional = false;
        }
    }

    /**
     * Navigate back to opened meter information.
     */
    navigateToOrderDetail() {
        const queryParamsValue: Params = { meterId: this.id };
        this.router.navigate(['field-reporting/dashboard'],{
            queryParams: queryParamsValue,
            queryParamsHandling: 'merge'
        })
    }
}

interface ImageData {
    image_place: string;
    title: string;
    image_key: string;
}
