import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable, Subject, catchError, forkJoin, of, switchMap, takeUntil, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import 'leaflet-draw';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { WorkOrder } from '@shared/models/work-order';
import { AfterViewInit } from '@angular/core';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { ChangeDetectorRef } from '@angular/core';
import { RowNumber } from '@modules/planner/components/weekly-planning-map/weekly-planning-map.component';
declare const L: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private fieldReporting: FieldReportingService,
    private translateService: TranslateService,
    private workOrderService: WorkOrderService,
    private plannerProjectService: PlannerProjectService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) { }

  workOrdersLoading = true;
  userid: any;
  customerId: any;
  currentProject: any;
  projects = Array();
  workerteams = Array();
  currentWorkerteam;
  tab = 0;
  currentUser;
  workOrderArray = Array()
  displayedWorkOrders = Array()
  // allOrders = Array()
  mustDoForTodayOrders = Array()
  doneOrders = Array()
  toDoCount = 0;
  doneCount = 0;
  freeLocationsCount = 0;
  tabTitle = ''
  topIcon = 0
  expandInventoryList = 'assets/icons/expand_more_gray_24dp.svg';
  showInventoryList = false;
  showDashboard = true;
  showSingleOrderDetail = false;
  workOrderId;
  runGetWorkOrder = false;
  typeArray = Array();
  lat: number | null = null
  lon: number | null = null
  sortChoise = 'default';

  map: any
  editableLayers: any
  drawControl: any

  selectedWorkOrder: WorkOrder | null = null;
  selectedMarker: L.Marker | null = null;
  selectedColor = 'grey';
  selectedStatusId = 0;
  currentStatusId = 0;

  allWorkOrders: WorkOrder[] = [];
  allStatuses: any[] = [];
  currentProjectId;
  workOrderMarkers: Map<number, L.Marker> = new Map();
  locationMarker: any;

  BBOXFin = {
    sw: { lat: 60.17862810921057, lon: 23.963623690080446 },
    ne: { lat: 60.1847311378856, lon: 23.98555806249057 }
  };

  BBOXSwe = {
    sw: { lat: 60.039062, lon: 18.528942 },
    ne: { lat: 60.032419, lon: 18.560578 }
  }

  centerLatitude = 0;
  centerLongitude = 0;
  rowNumbers;
  rowNumberMarkers;
  mapReady = new Subject<void>();
  type: number = 1;


  ngOnInit(): void {
    this.setUpCustomRectangleCanvas();
    this.setUpCustomTextMarkerCanvas();
    this.sortChoise = 'default';
    this.getCurrentProject();
    this.setTabTitle()
    // If we navigate from create report page use this to access current meter's information directly
    this.route.queryParams.subscribe(params => {
      if (params['meterId']) {
        this.workOrderId = params['meterId'];
        this.showDashboard = false;
        this.showSingleOrderDetail = true;
        this.runGetWorkOrder = true;
      }
    })
    this.mapReady.subscribe(() => {
      this.drawRowNumbersOnMap();
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  ngAfterViewInit() {
    // Ensure the map container is visible and has been rendered in the DOM
    setTimeout(() => this.initMap(), 0);
  }

  addZoomListener() {
    this.map.on('zoomend', () => {
      const currentZoomLevel = this.map.getZoom();
      const markerSize = this.getMarkerSize(currentZoomLevel); // Set marker size based on zoom level
      const inverterSize = this.getInverterSize(currentZoomLevel); 
      this.workOrderMarkers.forEach(marker => {
        if (this.type === 2) marker.options['size'] = inverterSize
        else marker.options['size'] = markerSize
      })})
  }



  /**
   * Get user info and set projects variable from it.
   * Set also workerteams correctly
   */
  getCurrentProject() {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.userid = data.id;
        this.customerId = data.customer_id;
        if(this.centerLatitude == 0 || this.centerLongitude == 0) {
          if(this.getCenterCoordinates()) {
            this.centerMap()
          }
        }
        this.currentProjectId = data.current_project;
        this.getRowNumbers(this.currentProjectId).subscribe(
          {next: () => this.mapReady.next()}
        )
        this.projects = Array();
        // Loop through projects to add them to projects array. If
        // project id matches current project number set its name to current project variable
        for (let i = 0; i < Object.keys(data.projects).length; i++) {
          if (Object.keys(data.projects)[i] == data.current_project) this.currentProject = Object.values(data.projects)[i]
          if (Object.values(data.projects)[i] != this.currentProject) this.projects.push({ name: Object.values(data.projects)[i], id: Object.keys(data.projects)[i] })
        }
        // Loop through workers data and push workerteams to workerteams variable and allworkerteams variable
        // Workerteams variable is active variable and allworkerteams is for later usage for accessing all original workerteams
        for (let i = 0; i < Object.keys(data.workerteams).length; i++) {
          this.workerteams.push({ values: Object.values(data.workerteams)[i], id: Object.keys(data.workerteams)[i], show: true })
        }

        if (this.workerteams.length > 0) {
          let json = localStorage.getItem('field-reporting-workerteam-solar')
          if (json) {
            let workerteamId = JSON.parse(json).id
            if (workerteamId && this.workerteams.find(c => c.id === workerteamId)) {
              let workerteam = this.workerteams.find(c => c.id === workerteamId)
              this.currentWorkerteam = workerteam
            } else {
              this.workerteams[0].show = false
              this.currentWorkerteam = this.workerteams[0]
            }
          } else {
            this.workerteams[0].show = false
            this.currentWorkerteam = this.workerteams[0]
          }
          if (this.currentWorkerteam) this.currentStatusId = this.currentWorkerteam.values.status_id;
          this.currentUser = data.id;
          this.getAllStatuses(this.currentProjectId).subscribe(statusData => {
            this.allStatuses = statusData.message;
            forkJoin([
              this.getWorkerteamOrders()
            ]).subscribe(() => {});
          });
        }
      }
      )
  }



  /**
   * Switch project by pressing another project in list from interface
   * @param projectId correct project id to change to
   */
  switchProject(projectId) {
    this.userService.updateCurrentProject(this.currentUser, projectId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => window.location.reload()
      )
  }

  hasReservation(workorder) {
    if (workorder && workorder.reservations) {
      const reservationKeys = Object.keys(workorder.reservations).map(Number);
      if (reservationKeys.includes(parseInt(this.currentWorkerteam.id))) {
        return true
      }
    }
    return false
  }

  /**
   * Go through work orders that workerteam has and apply data accordingly
   */
  getWorkerteamOrders(): Observable<any> {
    this.toDoCount = 0;
    this.doneCount = 0;
    this.freeLocationsCount = 0;

    this.workOrdersLoading = true
    this.displayedWorkOrders = [];
    this.workOrderArray = [];
    this.mustDoForTodayOrders = [];
    let status = this.allStatuses.find(status => status.id == this.currentStatusId)
    this.type = status.type
    return this.fieldReporting.getFieldReporting(status.type).pipe(
      tap(data => {
        this.allWorkOrders = data.all;
        Object.values(this.allWorkOrders).forEach((workorder: WorkOrder) => {
          if (this.hasReservation(workorder) || status.type === 3) {
            this.workOrderArray.push(workorder)
          }
        });
        let todaysOrders: WorkOrder[] = data.today;
        Object.values(todaysOrders).forEach((workorder: WorkOrder) => {
          if (this.hasReservation(workorder) || status.type === 3) {
            this.mustDoForTodayOrders.push(workorder)
          }
        });

        this.getDoneWorkOrders(this.currentWorkerteam.id);
        this.getLocation();
        this.workOrdersLoading = false
      }),
      takeUntil(this.componentDestroyed$)
    );
  }

  getDoneWorkOrders(teamId: number): void {
    this.fieldReporting.getDoneWorkOrdersForTeam(Number(teamId), this.type).pipe(
      tap(data => {
        this.doneOrders = data;
        this.tabChanger(this.tab)
        this.initMap()
      }),
      catchError(error => {
        console.error('Error fetching done work orders:', error);
        return of([]);
      })
    ).subscribe();
  }

  getAllStatuses(projectId): Observable<any> {
    return this.plannerProjectService.getStatuses(projectId)
  }


  initMap() {

    this.getCenterCoordinates();

    if (this.map) {
      this.drawWorkOrders();
      return;
    }


    this.map = L.map('mapmap', { editable: true });


    this.map.zoomControl.setPosition('topright');

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 23,
      minZoom: 15,
      detectRetina: true,
    }).addTo(this.map);

    this.map.on('locationerror', () => {
      this.map.panTo({ lat: this.centerLatitude, lon: this.centerLongitude });
    });

    // Set the initial center of the map
    this.centerMap()

    this.drawWorkOrders();

    let drawPluginOptions = {
      position: 'topright',
      draw: {
        polygon: {
          allowIntersection: false,
          drawError: {
            color: '#e1e100',
            message: '<strong>Oh snap!<strong> you can\'t draw that!'
          },
          shapeOptions: {
            color: '#97009c'
          }
        },
        // disable toolbar item by setting it to false
        polyline: false,
        circle: false,
        rectangle: false,
        marker: false,
        circlemarker: false
      },

      edit: {
        featureGroup: this.editableLayers, //REQUIRED!!
        remove: false,
        edit: false
      }

    };

    this.drawControl = new L.Control.Draw(drawPluginOptions);
    this.addZoomListener()
  }

  getCenterCoordinates(): boolean {
    switch (this.customerId) {
      case 1:
        this.centerLatitude = 60.18208069645202
        this.centerLongitude = 23.97548906518469
        return true
      case 2:
        this.centerLatitude = 60.03611032213076
        this.centerLongitude = 18.55047482522265
        return true
      default:
        this.centerLatitude = 0
        this.centerLongitude = 0
        return false
    }
  }

  centerMap() {
    const initialCenter = L.latLng(this.centerLatitude, this.centerLongitude);
    this.map.setView(initialCenter, 16);
  }

  getMarkerSize(zoomLevel: number): [number, number] {
    if (this.customerId == 1) {
      switch (zoomLevel) {
        case 19:
          return [35, 35]
        case 18:
          return [15, 15];
        case 17:
          return [7, 7];
        case 16:
          return [1.5, 1.5];
        case 15:
          return [0.5, 0.5];
        default:
          return [60, 60];
      }
    } else {
      switch (zoomLevel) {
        case 23:
                return [170, 40]
        case 22:
                return [140, 40]
        case 21:
                return [110, 30]
          case 20:
                return [90, 20]
        case 19:
          return [60, 10]
        case 18:
          return [30, 5];
        case 17:
          return [15, 1.5];
        case 16:
          return [13, 0.5];
        case 15:
          return [6, 0.3];
        default:
          return [60, 10];
      }
    }
  }
  getInverterSize(zoomLevel: number): [number, number] {
    switch (zoomLevel) {
      case 19:
        return [35, 35]
      case 18:
        return [15, 15];
      case 17:
        return [7, 7];
      case 16:
        return [1.5, 1.5];
      case 15:
        return [0.5, 0.5];
      default:
        return [60, 60];
    }
  }

  drawWorkOrders() {
    // Remove any existing work order markers from the map
    if (this.workOrderMarkers) {
      this.workOrderMarkers.forEach(marker => marker.remove());
    }
    this.workOrderMarkers = new Map(); // Reset the work order markers array

    const currentZoomLevel = this.map.getZoom(); // Get current zoom level
    const markerSize = this.getMarkerSize(currentZoomLevel); // Set marker size based on zoom level
    const inverterSize = this.getInverterSize(currentZoomLevel)

    const canvasRenderer = L.canvas(); // Create a canvas renderer

    Object.values(this.allWorkOrders).forEach((workOrder: WorkOrder) => {
      let color = this.getWorkOrderColor(workOrder);
      let opacity = 1
      if (workOrder.interrupted) {
        color = 'red';
      }
      let size = markerSize
      if (workOrder.type === 2) size = inverterSize
      const lat = Number(workOrder.latitude);
      const lng = Number(workOrder.longitude);

      if (!isNaN(lat) && !isNaN(lng) && this.map) {
        const marker = L.customRectangleMarker([lat, lng], {
          renderer: canvasRenderer,
          size: size,
          fillColor: color,
          color: color,
          opacity: opacity,
          fillOpacity: opacity,
          weight: 1, // Ensure the border weight is specified
          isSelected: false // Initialize as not selected
      }).addTo(this.map);

        marker.workOrder = workOrder;
        marker.color = color;

        marker.on('click', () => {
          if (this.selectedWorkOrder && this.selectedWorkOrder.id === workOrder.id) {
            // Deselect if the same work order is clicked again
            this.deselectWorkOrder();
            this.updateMarkerStyle(marker, color, opacity, false);
          } else {
            // Deselect the previously selected work order, if any
            if (this.selectedMarker && this.selectedWorkOrder) {
              this.updateMarkerStyle(this.selectedMarker, this.getWorkOrderColor(this.selectedWorkOrder), opacity, false)
              this.deselectWorkOrder()
            }
            // Select the new work order
            this.selectedWorkOrder = workOrder;
            if (this.doneOrders.some(order => order.id === workOrder.id)) {
              let order = this.doneOrders.find(order => order.id === workOrder.id);
              this.selectedWorkOrder['rid'] = order ? order.rid : 0
              this.selectedWorkOrder['notes'] = order.notes
            }
            this.selectedMarker = marker;
            this.updateMarkerStyle(marker, color, opacity, true);
            // Trigger Angular's change detection to update the view
            this.changeDetectorRef.detectChanges();
          }
        });

        // Add the marker to the workOrderMarkers array for later access
        this.workOrderMarkers.set(workOrder.id, marker);
      } else {
        console.log("Error, problem with workorder coordinates");
      }
    });
  }

  createIcon(size: number, color) {
    return L.divIcon({
      className: '',
      iconSize: [size, size], // square size
      html: `<div style="width: 85%; height: 70%; background-color: ${color}; opacity: 1;"></div>`
    });
  }


  closeDetails(): void {
    if (this.selectedMarker && this.selectedWorkOrder) {
      this.updateMarkerStyle(this.selectedMarker, this.getWorkOrderColor(this.selectedWorkOrder), 1, false)
      this.selectedWorkOrder = null;
    }
    // If you're using ChangeDetectorRef, you might need to call detectChanges()
    // this.changeDetectorRef.detectChanges();
  }

  // Check if we show create report button
  // if order doesn't belong to user, return false
  // if it does, but is already reported, return false
  // ...unless the report is interrupt, in which case return true
  // otherwise return true
  showCreateButton(workOrder: WorkOrder): boolean {
    let order = this.workOrderArray.find(order => order.id === workOrder.id)
    if (order) {
      if (order.interrupted) {
        return false
      }
      if (workOrder && this.hasReservation(workOrder) || workOrder.type === 3) {
        let doneOrder = this.doneOrders.find(order => order.id === workOrder.id)
        if (doneOrder) {
          if (doneOrder.report_type == 2) {
            return true
          }
        } else {
          return true
        }
      }
    }
    return false
  }



  // Method to deselect the current work order
  deselectWorkOrder(): void {
    if (this.selectedMarker) {
      //this.resetMarkerStyle(this.selectedMarker);
      this.selectedMarker = null;
    }
    this.selectedWorkOrder = null;
    // Trigger Angular's change detection to update the view
    this.changeDetectorRef.detectChanges();
  }

  // Method to update the marker style for the selected marker
  updateMarkerStyle(marker, color, opacity, isSelected) {
    if (isSelected) {
      // Update the marker to be white with a black outline
      marker.setStyle({
        fillColor: 'white',
        color: 'black',
        fillOpacity: opacity,
        weight: 2 // Adjust the outline thickness as needed
      });
    } else {
      // Revert to the original style
      marker.setStyle({
        fillColor: color,
        color: color,
        fillOpacity: opacity,
        weight: 1 // Adjust the outline thickness as needed
      });
    }

    // Update the isSelected property
    marker.options.isSelected = isSelected;
  }

  // Method to reset the marker style for the deselected marker
  // resetMarkerStyle(marker: L.Marker): void {
  //   const workOrder = (marker as any).workOrder as WorkOrder; // Type assertion
  //   const color = this.getWorkOrderColor(workOrder);
  //   const newSize = this.calculateIconSizeBasedOnZoom(this.map.getZoom());
  //   const defaultIcon = L.divIcon({
  //     className: '',
  //     iconSize: [newSize, newSize], // Adjust the size if needed
  //     html: `<div style="width: 85%; height: 70%; background-color: ${color}; opacity: 1;"></div>`,
  //   });

  //   marker.setIcon(defaultIcon);
  // }


  getStatusColor(statusId: number): string {
    for (let status of this.allStatuses) {
      if (status.id === statusId) {
        return status.color;
      }
    }
    return 'white';
  }



  createReport(): void {
    // Logic to create a report for the selected work order
    // ...
  }

  getStatusName(statusId: number): string {
    for (let i = 0; i < this.allStatuses.length; i++) {
      if (this.allStatuses[i].id === statusId) {
        const definition = JSON.parse(this.allStatuses[i].definition);
        return definition.en; // Get the English translation for now
      }
    }
    return 'Unknown';
  }

  getWorkOrderColor(workOrder: WorkOrder): string {
    // Doesn't have reservation in users workerteam
    if (!this.hasReservation(workOrder) && workOrder.type !== 3) {
      return '#79829C';
    }
    //Check if the workorder has been reported
    let doneOrder = this.doneOrders.find(order => order.id === workOrder.id)
    if (doneOrder) {
      // deviation
      if (doneOrder.report_type == 3) {
        return '#FF9F0A';
      }
      // normal
      if (doneOrder.report_type == 1) {
        if (workOrder.type === 3) {
          let status = this.allStatuses.find(s => s.id === workOrder.status_id)
          if (status) {
            if (status.state === 1) return '#0058FF'
            else if (status.state === 2) return '#26ed53'
            else if (status.state === 3) return '#1dc443'
            else if (status.state === 4) return '#106e26'
            else return '#0a4016'
          } else return '#0a4016'
        } else return '#10A231';
      }
    }

    // Interrupted
    if (workOrder.interrupted) {
      return '#E8132B';
    }

    // Check if the work order is in the mustDoForTodayOrders array
    if (this.mustDoForTodayOrders.some(order => order.id === workOrder.id)) {
      return '#0058FF';
    }

    // Check if the work order is in the workOrderArray
    if (this.workOrderArray.some(order => order.id === workOrder.id)) {
      return '#A921FF';
    }


    // Default color if not found in the above arrays
    return '#79829C';
  }




  /**
   * Change tab so that we display correct information in interface
   */
  tabChanger(tab) {
    this.topIcon = 0
    this.tab = tab
    // if (tab == 1) {
    //   this.displayedWorkOrders = this.workOrderArray
    // } else if (tab == 2) {
    //   this.displayedWorkOrders = this.mustDoForTodayOrders
    // } else if (tab == 3) {
    //   this.displayedWorkOrders = this.doneOrders
    // }
    if (tab == 3) {
      this.displayedWorkOrders = this.doneOrders
    }
    else if (tab == 0) {
      this.onTabSwitchedToMap();
    }
    // this.changeFilter(this.sortChoise)
    this.setTabTitle()
  }

  onTabSwitchedToMap() {
    if (this.map) {
      setTimeout(() => this.map.invalidateSize(), 0);
    } else {
      this.initMap();
    }
  }

  expandArrowListener(id) {
    var img = document.getElementById('arrowImg-' + id) as HTMLImageElement

    if (img.src.includes('expand_more_blue_24dp.svg')) {
      img.src = 'assets/icons/expand_less_blue_24dp.svg'

    } else if (img.src.includes('expand_less_blue_24dp.svg')) {
      img.src = 'assets/icons/expand_more_blue_24dp.svg'
    }
  }

  setTabTitle() {
    if (this.tab == 1) {
      this.tabTitle = 'All'
    } else if (this.tab == 2) {
      this.tabTitle = 'Must do for today'
    } else if (this.tab == 3) {
      this.tabTitle = 'Done'
    }
  }

  toSingleWorkOrder(id, status, wid, rid = 0) {
    let queryParamsValue: Params = {wid: wid};
    if (rid > 0) {
      queryParamsValue['rid'] = rid;
    }

    let url = '/field-reporting/report/' + id
    this.router.navigate([url], {
      queryParams: queryParamsValue,
      queryParamsHandling: 'merge'
    })


    // this.workOrderId = id;
    // this.showDashboard = false;
    // this.showSingleOrderDetail = true;
    // this.runGetWorkOrder = true;
    // this.selectedStatusId = status;

  }

  /**
   * Sets attributes dynamically to color and borderm-bottom
   * @param tabId tabId which is 1-3
   * @returns correct color and border-botto attribute for tab
   */
  styleObject(tabId): Object {
    if (this.tab == tabId) return { 'color': '#0058ff', 'border-bottom': '#0058ff 4px solid' }
    else return { 'color': 'gray', 'border-bottom': '0px solid white' }
  }

  /**
   * Switches to correct workerteam, removes correct workerteam from list and runs getWorkerteamOrders function
   * @param id what workerteam we want to switch to
   */
  switchWorkerteam(id) {
    this.workerteams.forEach(d => d.show = true)
    let workerteam = this.workerteams.find(c => c.id === id)
    if (workerteam) {
      workerteam.show = false
      this.currentWorkerteam = workerteam
      this.currentStatusId = parseInt(workerteam.values.status_id)
      localStorage.setItem('field-reporting-workerteam-solar', JSON.stringify({id: workerteam.id}))
    }

    this.getWorkerteamOrders().subscribe(() => {});
  }

  /**
   * Toggle dashboard on and hide single order detail. Also change runGetWorkOrder to false so
   * that it can be changed to true when needs to be run in single order detail component. This function is
   * called when single order detail is closed
   */
  showDashboardToggle() {
    this.showDashboard = true;
    this.showSingleOrderDetail = false;
    this.runGetWorkOrder = false;
  }

  timeWindowToHours(time) {
    time = time.split(" ")[1]
    let hours = time.split(":")[0]
    let minutes = time.split(":")[1]

    if (hours == '0') {
      hours = '00'
    }
    if (minutes == '0') {
      minutes = '00'
    }

    return hours + ':' + minutes
  }

  timeWindowToDate(time) {
    time = time.split(" ")[0]
    time = time.replaceAll("/", "-")
    var newDate = new Date(time);
    var date = newDate.getDate() + '.' + (newDate.getMonth() + 1) + '.' + newDate.getFullYear()
    return date
  }

  setCornerIcon(accessType) {
    if (accessType == 3) return "assets/icons/key_blue_24dp.svg"
    else if (accessType == 2) return "assets/icons/person_lock_blue_24dp.svg"
    else if (accessType == 1) return "assets/icons/lock_unlocked_blue_24dp.svg"
    else return null
  }

  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lon = position.coords.longitude;

        if (this.lat && this.lon && ((this.inBBOXFin(this.lat, this.lon) || this.inBBOXSwe(this.lat, this.lon)))) {
          this.centerLatitude = this.lat;
          this.centerLongitude = this.lon;

          // Center the map on the user's location
          this.map.setView([this.lat, this.lon], this.map.getZoom());

          // If inside BBOX, use current location
          const marker = L.marker([this.lat, this.lon], {
            icon: L.divIcon({
              className: '',
              iconSize: [8, 8], // square size
              html: `<div style="width: 100%; height: 100%; background-color: #00CED1; border-radius: 50%; border: 1px solid black;"></div>`
            })
          }).addTo(this.map)
            .bindPopup(this.translateService.instant('fieldReporting.dashboard.youAreHere')).openPopup();

          setTimeout(() => {
            marker.closePopup();
          }, 2000);

          this.locationMarker = marker;

          navigator.geolocation.watchPosition(position => {
            const crd = position.coords;
            this.lat = crd.latitude;
            this.lon = crd.longitude;
            let latlng = new L.LatLng(this.lat, this.lon);
            this.locationMarker.setLatLng(latlng);
          }, (error) => {
            console.log("Error occurred. Error code: " + error.code);
          }, {
            enableHighAccuracy: true,
            maximumAge: 30000,
            timeout: 27000
          });
        }
      });
    } else {
      console.log("No support for geolocation");
    }
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies
  calcDistance(lat1, lon1, lat2, lon2) {
    let Radius = 6371; // km
    let dLat = this.toRad(lat2 - lat1);
    let dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);
    let returnVal = ""

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = Radius * c; // km

    return d
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return Value * Math.PI / 180;
  }

  inBBOXFin(lat, lon) {
    return (
      lat >= this.BBOXFin.sw.lat && lat <= this.BBOXFin.ne.lat &&
      lon >= this.BBOXFin.sw.lon && lon <= this.BBOXFin.ne.lon
    );
  }

  inBBOXSwe(lat, lon) {
    return (
      lat >= this.BBOXSwe.sw.lat && lat <= this.BBOXSwe.ne.lat &&
      lon >= this.BBOXSwe.sw.lon && lon <= this.BBOXSwe.ne.lon
    );
  }

  setUpCustomRectangleCanvas() {
    L.Canvas.include({
      _updateCustomRectangle: function (layer) {
        const ctx = this._ctx;
        const p = layer._point;
        const size = layer.options.size;
        const halfWidth = size[0] / 2;
        const halfHeight = size[1] / 2;

        ctx.save();
        ctx.beginPath();
        ctx.rect(p.x - halfWidth, p.y - halfHeight, size[0], size[1]);
        ctx.fillStyle = layer.options.fillColor;
        ctx.fill();
        ctx.strokeStyle = layer.options.color;
        ctx.lineWidth = layer.options.weight;
        ctx.stroke();
        ctx.restore();
      }
    });

    L.CustomRectangleMarker = L.CircleMarker.extend({
      options: {
        size: [20, 10], // width, height
      },

      _updatePath: function () {
        this._renderer._updateCustomRectangle(this);
      }
    });

    L.customRectangleMarker = function (latlng, options) {
      return new L.CustomRectangleMarker(latlng, options);
    };
  }


  setUpCustomTextMarkerCanvas() {
    L.Canvas.include({
      _updateCustomText: function (layer) {
        const ctx = this._ctx;
        const p = layer._point;
        const text = layer.options.text;
        const fontSize = layer.options.fontSize || 12;
        const fontColor = layer.options.fontColor || 'black';

        ctx.save();
        ctx.font = `${fontSize}px Arial`;
        ctx.fillStyle = fontColor;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, p.x, p.y);
        ctx.restore();
      }
    });

    L.CustomTextMarker = L.CircleMarker.extend({
      options: {
        text: '',
        fontSize: 12,
        fontColor: 'black'
      },

      _updatePath: function () {
        this._renderer._updateCustomText(this);
      }
    });

    L.customTextMarker = function (latlng, options) {
      return new L.CustomTextMarker(latlng, options);
    };

  }

  getRowNumbers(projectId): Observable<RowNumber[]> {
    return this.workOrderService.getRowNumbers(projectId).pipe(
      tap((rowNumbers: RowNumber[]) => {
        this.rowNumbers = rowNumbers;
      })
    );
  }

  drawRowNumbersOnMap(): void {
    // Remove any existing row number markers from the map
    if (this.rowNumberMarkers) {
      this.rowNumberMarkers.forEach(marker => marker.remove());
    }
    this.rowNumberMarkers = new Map<number, L.Layer>(); // Reset the row number markers array

    const canvasRenderer = L.canvas(); // Create a canvas renderer

    this.rowNumbers.forEach((rowNumber) => {
      const lat = Number(rowNumber.position_x);
      const lng = Number(rowNumber.position_y);

      if (!isNaN(lat) && !isNaN(lng)) {
        // Create a custom text marker
        const marker = L.customTextMarker([lat, lng], {
          renderer: canvasRenderer,
          text: rowNumber.number.toString(),
          fontSize: 12, // Adjust font size as needed
          fontColor: 'black' // Adjust font color as needed
        }).addTo(this.map);

        // Add the marker to the rowNumberMarkers array
        this.rowNumberMarkers.set(rowNumber.id, marker);
      } else {
        console.error(`Invalid coordinates for row number id=${rowNumber.id}: lat=${lat}, lng=${lng}`);
      }
    });
  }

}
