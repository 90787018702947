<div class="page-container h-100 pb-5">
    <div class="page-content h-100">

        <div class="row mb-3 justify-content-center">
            <div class="col-auto text-center mr-3">
                <div>
                    <label class="gray-text">{{'planner.weeklyPlanningMap.showMapLayer' | translate}}</label><br>
                    <!-- Comparison changes type from string to boolean what is sent to function -->
                    <select class="btn btn-secondary fs-small" (change)="changeMainSelect($event.target.value);selectedFilter = 'filterOff'; changeFilter()">
                        <option value="0">{{'planner.weeklyPlanningMap.frames' | translate}}</option>
                        <option value="1">{{'planner.weeklyPlanningMap.inverters' | translate}}</option>
                    </select>
                </div>
            </div>
            <div class="col-auto">
                <label class="gray-text">{{ 'basic.selectPhase' | translate }}</label>
                <div ngbDropdown>
                    <button class="btn btn-secondary" type="button" id="dropdownPhase" ngbDropdownToggle>
                        <span *ngIf="selectedStatus">{{selectedStatus.definition}}</span>
                        <span *ngIf="!selectedStatus">No status</span>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownPhase">
                        <div *ngFor="let status of statuses">
                            <button ngbDropdownItem type="button" (click)="onStatusChange(status, true)">{{status.definition}}</button>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="col-auto text-center mr-3">
                <div>
                    <label class="gray-text">{{'basic.selectWorkerteam' | translate}}</label><br>
                    <select class="btn btn-secondary fs-small" [disabled]="workerteamsLoading" [(ngModel)]="selectedWorkerteam"
                        (change)="changeWorkerteamFilter($event)">
                        <option value="filterOff">{{'basic.all' | translate}}</option>
                        <option *ngFor="let group of groups" [ngValue]="group.id">
                            {{ group.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-auto text-center">
                <label class="gray-text">{{'basic.filter' | translate}}</label><br>
                <select class="btn btn-secondary fs-small" [(ngModel)]="selectedFilter" (change)="changeFilter()">
                    <option value="filterOff">{{'basic.filterOff' | translate}}</option>
                    <option value="scheduled">{{'basic.scheduled' | translate}}</option>
                    <option value="notScheduled">{{'basic.notScheduled' | translate}}</option>
                    <option value="done">{{'basic.done' | translate}}</option>
                    <option value="interrupted">{{'basic.interrupted' | translate}}</option>
                </select>
            </div>
            <div class="col-auto text-center">
                <img class="card-icon" src="assets/icons/info_blue_24dp.svg" (mouseover)="showColors = true" (mouseout)="showColors = false">
                <div *ngIf="showColors" class="colorbox">
                    <div>
                        <span class="dot" [ngStyle]="{'background-color': interruptedColor}"></span>
                        {{'basic.interrupted' | translate}}
                    </div>
                    <div *ngFor="let status of statuses">
                        <span class="dot" [ngStyle]="{'background-color': status.color}"></span>
                        {{ status.definition }}
                    </div>
                </div>

            </div>
        </div>



        <div class="map-height">
            <div *ngIf="selectedMarkersCount > 0" class="selected-count">
                {{ selectedMarkersCount }} markers selected
                <div class="add-all-to-one-slot">
                    <input type="checkbox" [(ngModel)]="addToSingleSlot" id="singleSlotCheckbox">
                    <label for="singleSlotCheckbox">Add all to one slot</label>
                </div>
                <div *ngIf="selectedMarkers.length > 0" class="selected-ids">
                    <span *ngFor="let marker of selectedMarkers; let last = last">
                        <span *ngIf="marker.identifier">
                            <span *ngIf="customerId === 2 && marker.transformer">{{ marker.transformer }}-{{ marker.inverter }}<span *ngIf="marker.circuit"> - {{marker.circuit}}</span></span>
                            <span *ngIf="customerId !== 2 || !marker.transformer">{{marker.identifier}}</span>
                        </span>
                        <span *ngIf="!marker.identifier && marker.transformer">{{ marker.transformer }}-{{ marker.inverter }}</span>
                        <ng-container *ngIf="!last">, </ng-container>
                    </span>
                </div>
            </div>

            <div id="mapmap" class="map-container structure-child"></div>
        </div>

        <div class="bottom-container">
            <div class="row week-picker d-flex justify-content-center align-items-center" *ngIf="tableSpinner">
                <div class="col-4 mt-1">
                    <div class="d-flex justify-content-center mt-2">
                        <img class="chevron p-2" src="assets/icons/chevron_left_gray_24dp.svg" (click)="lastWeek()">
                        <div class="text-center px-3">
                            <span class="gray-text">{{'basic.week' | translate }} {{weekNr}}</span>
                            <br>
                            <span *ngIf="globalStartDate">{{ globalStartDate?.getDate() }}.{{
                                globalStartDate?.getMonth() + 1}}.{{ globalStartDate?.getFullYear() }} - {{
                                globalLastDate?.getDate() }}.{{ globalLastDate?.getMonth() + 1}}.{{
                                globalLastDate?.getFullYear() }}</span>
                        </div>
                        <img class="chevron p-2" src="assets/icons/chevron_right_gray_24dp.svg" (click)="nextWeek()">
                    </div>
                </div>
            </div>
            <!-- <load-spinner *ngIf="tableSpinner" class="spinner-table"></load-spinner> -->

            <div class="calendar-container">
                <div *ngFor="let calendar of calendarsDataArray" class="calendar">
                    <div style="display: flex; align-items: center;">
                        <h3 style="font-size: 20px; margin-right: 15px;">{{calendar.calendarData.name}}</h3>
                        <img src="/assets/icons/groups_black_24dp.svg" alt="Group Icon"
                            style="margin-right: 15px; transform: translateY(-5px);">
                        <!-- Added translateY transform -->
                        <span style="transform: translateY(-5px);">{{
                            getWorkerCountForSelectedCalendar(calendar.calendarData.id) }}</span>
                        <!-- Added translateY transform -->
                    </div>
                    <table class="table table-bordered text-center">
                        <tr>
                            <!-- <th scope="col"></th> -->
                            <th *ngFor="let day of calendarDays; let i = index" scope="col">
                                {{ getDayName(day) }} {{ getDatePart(day) }}.{{ getMonthPart(day) }}
                            </th>
                        </tr>
                        <tbody>
                            <td *ngFor="let slot of calendar.slotRows; let index = index" class="relative"
                                [style.z-index]="100" [ngClass]="{'slot-disabled': slot.disabled}">
                                <div class="slot-container"
                                     [style.background]="slot.color"
                                    [style.cursor]="selectSlotMode ? 'pointer' : null"
                                    (click)="selectSlot(slot.id, slot.date, calendar.calendarData.id)">
                                    <div class="mt-1 w-100 d-flex justify-content-center">
                                        <div class="left">
                                            <span *ngIf="!slot.workorders">{{calendar.calendarData.workdayLength.hours}}h {{calendar.calendarData.workdayLength.minutes}}m</span>
                                            <span *ngIf="slot.workorders">{{slot.starttime | date: 'HH:mm'}} - {{slot.endtime | date: 'HH:mm'}}</span>
                                        </div>
                                        <img src="/assets/icons/Bin.svg" alt="Delete Icon"
                                            (click)="emptySlot(slot.id, calendar.calendarData.id)">
                                    </div>
                                    <div class="d-table h-100 w-100" style="min-height: 30px;">
                                        <div class="slot-stuff">

                                            <div class="d-flex align-items-center justify-content-center"
                                                *ngIf="!slot.disabled && !bypassMode && selectSlotMode">
                                                <img height="30px" src="/assets/icons/add_gray_24dp.svg">
                                                <span *ngIf="slot.startHere === true"
                                                    class="start-here">{{'planner.weeklyPlanningMap.startHere' |
                                                    translate}}</span>
                                            </div>

                                            <div class="slot-details">
                                                <!-- Container for the first 9 work orders -->
                                                <div class="slot-items" [ngClass]="customerId === 2 ? 'slot-items-grid-template-1 d-flex align-items-center flex-column' : 'slot-items-grid-template-3'">
                                                    <div *ngFor="let workOrder of limitedWorkorders(slot.workorders)"
                                                        class="workorder-block" [style.background]="selectedStatus.color"
                                                        title="{{workOrder.identifier}}"
                                                        [ngClass]="customerId === 2 ? 'w-1' : 'w-3'">
                                                        <span *ngIf="customerId !== 2">{{workOrder.id}}</span>
                                                        <span *ngIf="customerId === 2 && workOrder.transformer">{{workOrder.transformer}} - {{workOrder.inverter}}<span *ngIf="workOrder.circuit"> - {{workOrder.circuit}}</span></span>
                                                        <span *ngIf="customerId === 2 && !workOrder.transformer">{{workOrder.identifier}}</span>
                                                    </div>
                                                </div>

                                                <!-- Custom expand arrow -->
                                                <div *ngIf="slot.workorders && slot.workorders.length > 9 && customerId !== 2"
                                                    class="expand-arrow" (click)="toggleExpand(slot.id)">
                                                    {{ expandedSlotId === slot.id ? '&#x25B2;' : '&#x25BC;' }}
                                                </div>
                                                <div *ngIf="slot.workorders && slot.workorders.length > 3 && customerId === 2"
                                                    class="expand-arrow" (click)="toggleExpand(slot.id)">
                                                    {{ expandedSlotId === slot.id ? '&#x25B2;' : '&#x25BC;' }}
                                                </div>

                                                <!-- Remaining work orders, shown only if expanded -->
                                                <div *ngIf="expandedSlotId === slot.id" class="slot-items" [ngClass]="customerId === 2 ? 'slot-items-grid-template-1 d-flex align-items-center flex-column' : 'slot-items-grid-template-3'">
                                                    <div *ngFor="let workOrder of remainingWorkorders(slot.workorders)"
                                                        class="workorder-block" [style.background]="selectedStatus.color"
                                                        title="{{workOrder.identifier}}"
                                                        [ngClass]="customerId === 2 ? 'w-1' : 'w-3'">
                                                        <span *ngIf="customerId !== 2">{{workOrder.id}}</span>
                                                        <span *ngIf="customerId === 2">{{workOrder.transformer}} - {{workOrder.inverter}} <span *ngIf="workOrder.circuit"> - {{workOrder.circuit}}</span></span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="!slot.disabled && !bypassMode && getTime(slot.starttime, slot.endtime)"
                                        class="progress-bars">
                                        <div *ngIf="slot.notFree || slot.freeAccess">
                                            <div class="dual-container mt-1">
                                                <div class="ml-1 left d-flex">
                                                    <img src="/assets/icons/calendar_available_black_24dp.svg">
                                                    <span *ngIf="slot.notFree" class="small">{{slot.notFree}}%</span>
                                                    <span *ngIf="!slot.notFree" class="small">0%</span>
                                                </div>
                                                <div class="progress progress-primary right">
                                                    <div class="progress-bar" role="progressbar"
                                                        [ngStyle]="setProgress(slot.notFree)" aria-valuenow="0"
                                                        aria-valuemin="0" aria-valuemax="100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dual-container mt-1 mb-1">
                                                <div class="ml-1 left d-flex">
                                                    <img src="/assets/icons/calendar_month_black_24dp.svg">
                                                    <span *ngIf="slot.freeAccess"
                                                        class="small">{{slot.freeAccess}}%</span>
                                                    <span *ngIf="!slot.freeAccess" class="small">0%</span>
                                                </div>
                                                <div class="progress progress-primary right">
                                                    <div class="progress-bar" role="progressbar"
                                                        [ngStyle]="{ 'width': calculateFillPercentage(slot, calendar.originalCalendarData.slot_max_default) }"
                                                        aria-valuenow="calculateFillPercentage(slot)" aria-valuemin="0"
                                                        aria-valuemax="100">
                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                        <div class="progress-bars-empty" *ngIf="!slot.notFree && !slot.freeAccess">
                                            <div class="align-center justify-content-center gray-text">
                                                <div class="dual-container justify-content-center mt-1 mb-1">
                                                    <span class="pill pill-3"
                                                        *ngIf="slot.workorders && slot.workorders.length > 0"
                                                        [ngClass]="slot.slot_max != null ? 'slot_max_pill' : 'default_pill'"
                                                        [ngStyle]="{'background-color': slot.slot_max != null ? selectedStatus.color : ''}">
                                                        {{ slot.fill }}/{{ slot.duration / 100 * (slot.slot_max !=
                                                        null ? slot.slot_max :
                                                        calendar.originalCalendarData.slot_max_default) }} min
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
