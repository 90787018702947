<!-- Progress dashboard starts -->
<div class="page-container">
  <div class="page-heading">
    <h3>
      <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
      <span class="page-title">{{'sideNavigation.titleDashboard' | translate}}</span>
    </h3>
    <div class="d-flex flex-wrap">
      <span class="blue-text me-1">All</span>
      <div class="d-flex align-items-center me-2">
        <div class="dot" [ngStyle]="{'background-color': interruptedColor}"></div>
        {{'basic.interrupted' | translate}}
      </div>
      <div class="d-flex align-items-center me-2">
        <div class="dot" style="background-color: white; border: 1px solid red"></div>
        {{'basic.deviation' | translate}}
      </div>
      <ng-container *ngIf="checkLength(allStates.states, 1) > 0">
        <div class="w-100"></div>
        <div class="blue-text me-1">
          <span *ngIf="customerId === 2">String</span>
          <span *ngIf="customerId !== 2">Frame</span>
        </div>
        <div *ngFor="let status of allStates.states| filterStatesByType: 1" class="d-flex align-items-center me-2">
          <div class="dot" [ngStyle]="{'background-color': status.color}"></div>
          {{ status.definition }}
        </div>
      </ng-container>
      <ng-container *ngIf="checkLength(allStates.states, 2) > 0">
        <div class="w-100"></div>
        <div class="blue-text me-1">Inverters</div>
        <div *ngFor="let status of allStates.states| filterStatesByType: 2" class="d-flex align-items-center me-2">
          <div class="dot" [ngStyle]="{'background-color': status.color}"></div>
          {{ status.definition }}
        </div>
      </ng-container>
      <ng-container *ngIf="checkLength(allStates.states, 3) > 0">
        <div class="w-100"></div>
        <div class="blue-text me-1">Frames(checkup)</div>
        <div *ngFor="let status of allStates.states| filterStatesByType: 3" class="d-flex align-items-center me-2">
          <div class="dot" [ngStyle]="{'background-color': status.color}"></div>
          {{ status.definition }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="page-content">

    <div class="map-height">
      <div id="mapProject" class="map-container structure-child"></div>
    </div>

    <div class="justify-content-center w-100 d-flex" *ngIf="pageLoading">
      <load-spinner *ngIf="pageLoading"></load-spinner>
    </div>
    <section class="section" *ngIf="!pageLoading">
      <h4>{{'planner.projectDashboard.titleStages' | translate}}</h4>
      <div class="card shadow-sm state-class p-3" *ngFor="let state of filterNotStartedState(allStates.states)">
        <hr>
        <h5><span class="dot" [ngStyle]="{'background-color': state.color}"></span>
          {{state.definition}} -
          <span *ngIf="state.type === 1" class="blue-text">
            <span *ngIf="customerId === 2">String</span>
            <span *ngIf="customerId !== 2">Frame</span>
          </span>
          <span *ngIf="state.type === 2" class="blue-text">Inverter</span>
          <span *ngIf="state.type === 3" class="blue-text">Checkup</span>
        </h5>
        <div class="fw-bold mb-2">
          <span *ngIf="state.type !== 3">{{'planner.projectDashboard.workorders' | translate }}</span>
          <span *ngIf="state.type === 3">{{'planner.projectDashboard.workordersHere' | translate }}</span>
          : {{state.workorders.amount}}</div>
        <div class="row">
            <div class="col-4" *ngIf="state.type !== 3">
                <h4>{{'planner.projectDashboard.today' | translate}}</h4>
                <div>{{'basic.done' | translate }}:
                  <span class="green-text">{{state.workorders.todaydone}}/{{ state.workorders.todaytotal}}</span>
                </div>
                <div>{{'planner.projectDashboard.interrupted' | translate }}:
                  <span [ngClass]="state.workorders.todayinterrupted > 0 ? 'red-text' : '' ">{{state.workorders.todayinterrupted}}</span>
                </div>
            </div>
            <div class="col-4" *ngIf="state.type !== 3">
                <h4>{{'planner.projectDashboard.thisWeek' | translate}}</h4>
                <div>{{'basic.done' | translate }}:
                  <span class="green-text">{{state.workorders.weekdone}}/{{state.workorders.weektotal}}</span>
                </div>
                <div>{{'planner.projectDashboard.interrupted' | translate }}:
                  <span [ngClass]="state.workorders.weekinterrupted > 0 ? 'red-text' : '' ">{{state.workorders.weekinterrupted}}</span>
                </div>
            </div>
            <div class="col-4">
                <h4>{{'planner.projectDashboard.wholeProject' | translate}}</h4>
                <div>{{'basic.done' | translate }}: <span class="green-text">
                  <span *ngIf="state.type !== 3">{{state.workorders.totaldone}}</span>
                  <span *ngIf="state.type === 3">{{getDoneAmount(state)}}</span>
                  / {{getAmount(state)}}</span>
                </div>
                <div *ngIf="state.type !== 3">
                    {{'planner.projectDashboard.interrupted' | translate }}: <span [ngClass]="state.workorders.totalinterrupted > 0 ? 'red-text' : '' ">{{state.workorders.totalinterrupted}}</span>
                </div>
            </div>
        </div>

      </div>
    </section>
      <div class="rightsidebar" *ngIf="showSidebar">
          <div class="sidebarcontent">
              <img src="assets/icons/close_red_24dp.svg" class="close-btn" (click)="closeSidebar()" />
              <h2 *ngIf="selectedMarker.identifier">
                <span *ngIf="customerId === 2 && selectedMarker.transformer">{{ selectedMarker.transformer }} - {{selectedMarker.inverter}}<span *ngIf="selectedMarker.circuit"> - {{selectedMarker.circuit}}</span></span>
                <span *ngIf="customerId === 2 && !selectedMarker.transformer">{{selectedMarker.identifier}}</span>
                <span *ngIf="customerId !== 2">{{selectedMarker.id}}</span>
              </h2>
              <h2 *ngIf="!selectedMarker.identifier && selectedMarker.inverter">{{ selectedMarker.transformer }} - {{selectedMarker.inverter}}</h2>

              <div *ngIf="selectedMarker.status_id">{{ 'planner.projectDashboard.currentPhase' | translate }}: {{getStatusDefinition()}}</div>

              <div *ngIf="selectedMarker.images">
                  <img *ngFor="let img of selectedMarker.images" src="{{img.url}}" alt="{{img.title}}" class="thumbnail" (click)="selectedImage = img.url" />
              </div>
              <hr>
              <h4>{{ 'planner.projectDashboard.reports' | translate }} (
                <span *ngIf="selectedMarker.images">{{selectedMarker.imagecount}}</span>
                <span *ngIf="!selectedMarker.images">0</span>
              )</h4>
              <div *ngFor="let state of allStates.states | filterStatesByType: type">
                <span class="dot" [ngStyle]="{'background-color': state.color}"></span>{{ state.definition }}
                <div *ngFor="let report of getStatusReports(state.state, selectedMarker)">
                  <div>
                    <span class="blue-text">{{report.created | date: 'dd.MM.yyyy HH:MM' }}</span>
                    <br *ngIf="report.notes">
                    <span *ngIf="report.notes" class="text-break"><img class="person-icon" src="assets/icons/comment_24dp_blue.svg"> {{ report.notes }}</span>
                    <br>
                    <img class="person-icon" src="assets/icons/person_blue_24dp.svg"> {{report.firstname}} {{report.lastname}}
                  </div>
                </div>
                <div>
                  <label class="cursor-pointer" (click)="state.show = !state.show">{{ 'planner.projectDashboard.images' | translate }} ({{ selectedMarker?.statuses?.[state.id]?.count || 0 }})</label>
                  <input class="cursor-pointer" type="radio" [checked]="state.show" (click)="state.show = !state.show">
                </div>
                <div *ngIf="selectedMarker?.statuses?.[state.id]?.images && state.show" class="panel">
                  <img *ngFor="let img of selectedMarker.statuses[state.id].images" src="{{img.url}}" alt="{{img.title}}" class="thumbnail" (click)="selectedImage = img.url" />
                </div>
                <hr>
              </div>
          </div>
      </div>
      <div *ngIf="isLoading" class="justify-content-center d-flex spinner">
          <load-spinner></load-spinner>
      </div>
      <!-- Full-size Image Modal -->
      <div class="modal" *ngIf="selectedImage" (click)="selectedImage = null">
          <div class="modal-content" (click)="$event.stopPropagation()">
              <span class="close" (click)="selectedImage = null">&times;</span>
              <img [src]="selectedImage" class="image-size-full">
          </div>
      </div>
  </div>

</div>
<!-- Project dashboard ends -->
