import { Component, OnInit } from '@angular/core';
import { UserService } from '@shared/services/user/user.service';
import { HttpClient } from "@angular/common/http";
import { forkJoin, Observable, of, Subject, switchMap, takeUntil } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { PlannerProjectService } from "@modules/planner/services/planner-project/planner-project.service";
import { WorkOrderService } from "@modules/planner/services/workorder/work-order.service";
import { WorkOrder } from '@shared/models/work-order';
import 'leaflet-draw';

declare const L: any;


@Component({
    selector: 'app-project-dashboard',
    templateUrl: './project-dashboard.component.html',
    styleUrls: ['./project-dashboard.component.scss'],
    providers: [HttpClient]
})
export class ProjectDashboardComponent implements OnInit {

    componentDestroyed$: Subject<boolean> = new Subject()
    allCalendars;
    teams = Array()
    projectId: any
    allStates: any = { "states": [] }
    pageLoading = true

    monDate: any
    tueDate: any
    wedDate: any
    thuDate: any
    friDate: any
    satDate: any
    sunDate: any
    todaysDate: any
    chosenDate: any
    today: any

    calendarsLoading = true
    weekdays: any
    calendarObjectIsSet = false
    thisDayHover = -1
    hoveredCalendar = -1
    customCalendarObject = {}
    showCalendarsWithSlots = false

    msas: any
    userid: any
    projectData: any
    projectName: any
    msaEstimatesLoading = true

    //map related stuff
    workOrders: WorkOrder[] = [];
    map: any
    workOrderMarkers: Map<number, L.Marker> = new Map();
    editableLayers: any
    drawControl: any
    showColors = false
    interruptedColor = "#ff1122";

    selectedMarker: any = { 'identifier': null, 'states': [], 'imagecount': 0 }
    selectedImage: any
    showSidebar: boolean = false
    isLoading: boolean = false
    selectedState: any


    customerId = 0;
    inverters: WorkOrder[] = []
    inverterMarkers: Map<number, L.Marker> = new Map();
    canvas = L.canvas()
    type: number = 0
    type3Workorders: WorkOrder[] = []
    type3WorkorderMarkers: Map<number, L.Marker> = new Map();
    rowNumbers: any
    rowNumberMarkers: any

    constructor(
        private userService: UserService,
        private plannerProjectService: PlannerProjectService,
        private workOrderService: WorkOrderService,
    ) { }

    ngOnInit() {
        this.setUpCustomRectangleCanvas();
        this.setUpCustomTextMarkerCanvas();
        this.todaysDate = new Date();
        this.today = this.todaysDate.getDay();
        
        // Get the current project ID first
        this.getCurrentProjectId(() => {
            // Use switchMap to first get row numbers, then get project data
            this.workOrderService.getRowNumbers(this.projectId)
                .pipe(
                    switchMap(rowNumbers => {
                        this.rowNumbers = rowNumbers; // Store the row numbers
                        
                        // After getting row numbers, fetch project data
                        return this.plannerProjectService.getProjectById(this.projectId);
                    }),
                    takeUntil(this.componentDestroyed$), // Ensure this happens after fetching project by ID
                    switchMap(projectData => {
                        this.projectData = projectData;
                        this.projectName = projectData.name;
    
                        // Fetch project statuses in parallel
                        return forkJoin({
                            status1: this.plannerProjectService.getProjectStatus(this.projectId, 1),
                            status2: this.plannerProjectService.getProjectStatus(this.projectId, 2),
                            status3: this.plannerProjectService.getProjectStatus(this.projectId, 3)
                        });
                    })
                )
                .subscribe({
                    next: statusData => {
                        // Combine status data into a single object
                        this.allStates = {
                            ...statusData.status1,
                            states: [
                                ...statusData.status1.states,
                                ...statusData.status2.states,
                                ...statusData.status3.states
                            ].map(state => ({ ...state, show: false }))
                        };
    
                        this.pageLoading = false;
    
                        // Fetch work orders and inverters simultaneously
                        forkJoin({
                            workorders: this.getWorkOrders(null, 1),
                            inverters: this.getWorkOrders(null, 2),
                            type3Workorders: this.getWorkOrders(null, 3)
                        }).subscribe({
                            next: results => {
                                this.workOrders = results.workorders;
                                this.inverters = results.inverters;
                                this.type3Workorders = results.type3Workorders;
                                this.initMap();
                            },
                            error: error => {
                                console.error('Error fetching inverters or work orders:', error);
                            }
                        });
                    },
                    error: error => {
                        console.error('Error fetching project or status:', error);
                    }
                });
        });
    }

    drawRowNumbersOnMap(): void {
        // Remove any existing row number markers from the map
        if (this.rowNumberMarkers) {
          this.rowNumberMarkers.forEach(marker => marker.remove());
        }
        this.rowNumberMarkers = new Map<number, L.Layer>(); // Reset the row number markers array
    
        const canvasRenderer = L.canvas(); // Create a canvas renderer
    
        this.rowNumbers.forEach((rowNumber) => {
          const lat = Number(rowNumber.position_x);
          const lng = Number(rowNumber.position_y);
    
          if (!isNaN(lat) && !isNaN(lng)) {
            // Create a custom text marker
            const marker = L.customTextMarker([lat, lng], {
              renderer: canvasRenderer,
              text: rowNumber.number.toString(),
              fontSize: 11, // Adjust font size as needed
              fontColor: 'black' // Adjust font color as needed
            }).addTo(this.map);
    
            // Add the marker to the rowNumberMarkers array
            this.rowNumberMarkers.set(rowNumber.id, marker);
          } else {
            console.error(`Invalid coordinates for row number id=${rowNumber.id}: lat=${lat}, lng=${lng}`);
          }
        });
      }

    getInverterSize(zoomLevel: number) : [number, number] {
        switch (zoomLevel) {
          case 19:
            return [35, 35]
          case 18:
            return [15, 15];
          case 17:
            return [7, 7];
          case 16:
            return [1.5, 1.5];
          case 15:
            return [0.5, 0.5];
          default:
            return [60, 60];
        }
      }


    getWorkOrders(limit: number | null, type: number): Observable<WorkOrder[]> {
        return this.workOrderService.getWorkOrders(limit, type);
    }

    /**
     * Gets current project id and calls a callback method to tell that it has finished
     * @param cb
     */
    getCurrentProjectId(cb) {
        this.userService.getUserInfo().subscribe(
            data => {
                this.projectId = data.current_project
                this.userid = data.id
                this.customerId = data.customer_id;
                // cb() = callback to let the other function know that this one is finished
                cb()
            }
        );
    }

    initMap() {
        const centerCoordinates = this.getCenterCoordinates();


        if (this.map) {
            this.drawWorkOrders();
            this.drawInverters()
            this.drawType3Workorders();
            return;
        }

        this.map = L.map('mapProject', { editable: true });

        this.map.zoomControl.setPosition('topright');

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20,
            minZoom: 15,
            detectRetina: true,
        }).addTo(this.map);

        this.map.on('locationerror', () => {
            this.map.panTo({ lat: centerCoordinates.latitude, lon: centerCoordinates.longitude });
        });

        // Set the initial center of the map
        const initialCenter = L.latLng(centerCoordinates.latitude, centerCoordinates.longitude);
        if (this.customerId == 1) {
            this.map.setView(initialCenter, 16);
        } else {
            this.map.setView(initialCenter, 15);
        }


        

        let drawPluginOptions = {
            position: 'topright',
            draw: {
                polygon: {
                    allowIntersection: false,
                    drawError: {
                        color: '#e1e100',
                        message: '<strong>Oh snap!<strong> you can\'t draw that!'
                    },
                    shapeOptions: {
                        color: '#97009c'
                    }
                },
                // disable toolbar item by setting it to false
                polyline: false,
                circle: false,
                rectangle: false,
                marker: false,
                circlemarker: false
            },

            edit: {
                featureGroup: this.editableLayers, //REQUIRED!!
                remove: false,
                edit: false
            }

        };

        this.drawControl = new L.Control.Draw(drawPluginOptions);
        
        this.drawRowNumbersOnMap()
        this.drawWorkOrders();
        this.drawInverters()
        this.drawType3Workorders()
        this.addZoomListener();
    }

    drawInverters() {
        if (this.inverterMarkers) {
          this.inverterMarkers.forEach(marker => marker.remove());
        }
        this.inverterMarkers = new Map();

        if (!this.map.getPane('inverterPane')) {
            this.map.createPane('inverterPane')
            this.map.getPane('inverterPane').style.zIndex = 20
        }

        const currentZoomLevel = this.map.getZoom(); // Get current zoom level
        const markerSize = this.getInverterSize(currentZoomLevel); // Set marker size based on zoom level


        this.inverters.forEach((inverter) => {
          let color = 'black';
          let border = 'black'
          let opacity = 1;

          if (inverter.interrupted) {
            color = this.interruptedColor;
            border = this.interruptedColor
        } else {
            for (let i = 0; i < this.allStates.states.length; i++) {
                const element = this.allStates.states[i];
                if (element.id == inverter.status_id) {
                    color = element.color
                    border = element.color
                    break;
                }
              }
        }
        if (inverter.deviation) {
            border = 'red';
        }

          const lat = Number(inverter.latitude);
          const lng = Number(inverter.longitude);

          if (!isNaN(lat) && !isNaN(lng)) {
            const marker = L.customRectangleMarker([lat, lng], {
              pane: 'inverterPane',
              renderer: this.canvas,
              size: markerSize,
              fillColor: color,
              color: border,
              opacity: opacity,
              fillOpacity: opacity,
              weight: 1,
              isSelected: false
            }).addTo(this.map);

            marker.on('click', () => this.showDetails(inverter.id, false));

            // Add the marker to the inverterMarkers array
            this.inverterMarkers.set(inverter.id, marker);
          } else {
            console.error(`Invalid coordinates for inverter id=${inverter.id}: lat=${lat}, lng=${lng}`);
          }
        });
    }

    updateMarkerStyle(marker, color, opacity, isSelected) {
        if (isSelected) {
            // Update the marker to be white with a black outline
            marker.setStyle({
                fillColor: 'white',
                color: 'black',
                fillOpacity: opacity,
                weight: 2 // Adjust the outline thickness as needed
            });
        } else {
            // Revert to the original style
            marker.setStyle({
                fillColor: color,
                color: color,
                fillOpacity: opacity,
                weight: 1 // Adjust the outline thickness as needed
            });
        }

        // Update the isSelected property
        marker.options.isSelected = isSelected;
    }

    drawWorkOrders() {
        // Remove any existing work order markers from the map
        if (this.workOrderMarkers) {
            this.workOrderMarkers.forEach(marker => marker.remove());
        }
        this.workOrderMarkers = new Map(); // Reset the work order markers array

        const currentZoomLevel = this.map.getZoom(); // Get current zoom level
        const markerSize = this.getMarkerSize(currentZoomLevel); // Set marker size based on zoom level

        if (!this.map.getPane('workorderPane')) {
            this.map.createPane('workorderPane')
            this.map.getPane('workorderPane').style.zIndex = 10
        }


        this.workOrders.forEach((workorder: WorkOrder) => {
            let color = 'gray';
            let border = 'gray';
            if (typeof workorder.status_id === 'number') {
                
                if (workorder.interrupted) {
                    color = this.interruptedColor;
                    border = this.interruptedColor
                } else {
                    for (let i = 0; i < this.allStates.states.length; i++) {
                        const element = this.allStates.states[i];
                        if (element.id == workorder.status_id) {
                            color = element.color
                            border = element.color
                            break;
                        }
                      }
                }
                if (workorder.deviation) {
                    border = 'red';
                }
                

                

                const opacity = 1;
                const lat = Number(workorder.latitude);
                const lng = Number(workorder.longitude);

                if (!isNaN(lat) && !isNaN(lng)) {
                    const marker = L.customRectangleMarker([lat, lng], {
                        pane: 'workorderPane',
                        renderer: this.canvas,
                        size: markerSize, // Size for rectangle
                        fillColor: color,
                        color: border,
                        opacity: opacity,
                        fillOpacity: opacity,
                        weight: 1, // Ensure the border weight is specified
                        isSelected: false // Initialize as not selected
                    }).addTo(this.map);

                    // Click event: show details
                    marker.on('click', () => this.showDetails(workorder.id, true));

                    // Add the marker to the workOrderMarkers map
                    this.workOrderMarkers.set(workorder.id, marker);
                } else {
                    console.error(`Invalid coordinates for workorder id=${workorder.id}: lat=${lat}, lng=${lng}`);
                }
            } else {
                console.log("Error, the workorder does not have a state")
            }
        });



    }

    drawType3Workorders() {
        // Remove any existing work order markers from the map
        if (this.type3WorkorderMarkers) {
            this.type3WorkorderMarkers.forEach(marker => marker.remove());
        }
        this.type3WorkorderMarkers = new Map(); // Reset the work order markers array

        const currentZoomLevel = this.map.getZoom(); // Get current zoom level
        const markerSize = this.getMarkerSize(currentZoomLevel); // Set marker size based on zoom level

        if (!this.map.getPane('workorderPane2')) {
            this.map.createPane('workorderPane2')
            this.map.getPane('workorderPane2').style.zIndex = 10
        }


        this.type3Workorders.forEach((workorder: WorkOrder) => {
            let color = 'green';
            let border = 'green';
            if (typeof workorder.status_id === 'number') {
                
                if (workorder.interrupted) {
                    color = this.interruptedColor;
                    border = this.interruptedColor
                } else {
                    for (let i = 0; i < this.allStates.states.length; i++) {
                        const element = this.allStates.states[i];
                        if (element.id == workorder.status_id) {
                            color = element.color
                            border = element.color
                            break;
                        }
                      }
                }
                if (workorder.deviation) {
                    border = 'red';
                }

                if (workorder.comment !== 0) {
                    border = 'black'
                }
                

                

                const opacity = 1;
                const lat = Number(workorder.latitude);
                const lng = Number(workorder.longitude);

                if (!isNaN(lat) && !isNaN(lng)) {
                    const marker = L.customRectangleMarker([lat, lng], {
                        pane: 'workorderPane2',
                        renderer: this.canvas,
                        size: markerSize, // Size for rectangle
                        fillColor: color,
                        color: border,
                        opacity: opacity,
                        fillOpacity: opacity,
                        weight: 1, // Ensure the border weight is specified
                        isSelected: false // Initialize as not selected
                    }).addTo(this.map);

                    // Click event: show details
                    marker.on('click', () => this.showDetails(workorder.id, true));

                    // Add the marker to the workOrderMarkers map
                    this.type3WorkorderMarkers.set(workorder.id, marker);
                } else {
                    console.error(`Invalid coordinates for workorder id=${workorder.id}: lat=${lat}, lng=${lng}`);
                }
            } else {
                console.log("Error, the workorder does not have a state")
            }
        });



    }

    getMarkerSize(zoomLevel: number): [number, number] {
        if (this.customerId == 1) {
          switch (zoomLevel) {
            case 19:
              return [45, 45]
            case 18:
              return [20, 20];
            case 17:
              return [12, 12];
            case 16:
              return [5, 5];
            case 15:
              return [3.5, 3.5];
            default:
              return [60, 60];
          }
        } else {
          switch (zoomLevel) {
            case 19:
              return [60, 10]
            case 18:
              return [30, 5];
            case 17:
              return [15, 1.5];
            case 16:
              return [13, 0.5];
            case 15:
              return [6, 0.3];
            default:
              return [60, 10];
          }
        }
      }

    createIcon(size: number, color, opacity, border) {
        return L.divIcon({
            className: '',
            iconSize: [size, size], // square size
            html: `<div style="width: 85%; height: 70%; background-color: ${color}; opacity: ${opacity}; border: ${border};"></div>`
        });
    }

    addZoomListener() {
        this.map.on('zoomend', () => {
          const currentZoomLevel = this.map.getZoom();
          const markerSize = this.getMarkerSize(currentZoomLevel); // Set marker size based on zoom level
          const markerSize2 = this.getInverterSize(currentZoomLevel); 
    
            this.workOrderMarkers.forEach(marker => {
              marker.options['size'] = markerSize
            })
            this.inverterMarkers.forEach(marker => {
              marker.options['size'] = markerSize2
            })
            this.type3WorkorderMarkers.forEach(marker => {
                marker.options['size'] = markerSize
              })
        
        });
      }

    /**
     * Converts a 2022-05-01 datestring to 01.05.2022 datestring.
     * @param datestring
     * @returns
     */
    dashesToDots(datestring) {
        var newdate = datestring.split("-").reverse().join("-")

        newdate = newdate.replace(/\-/g, '.')

        return newdate
    }

    /**
     * Removes "Aloittamatta" from states array.
     * Does not show not started situation
     * @param states all states by definition is shown in UI
     * @returns states array without single state
     */
    filterNotStartedState(states: any[]): any[] {
        return states.filter(s => 
            !(
                s.definition === 'Not started' || 
                s.definition === 'Done' ||
                s.definition === 'Ei aloitettu' || 
                s.definition === 'Valmis'
            )
        );
    }


    setProgress(amount: number, total: number, givenColor: any = null) {
        let progress;
        if (total > 0) {
            progress = parseFloat((amount / total * 100).toFixed(2))
        } else {
            progress = 0
        }

        let color = "#0058ff"
        // let color = "#FF9F0A"
        // if(progress >= 90 && progress <= 100) {
        //     color = "#10A231"
        // } else if(progress > 100) {
        //     color = "#0058FF"
        // }
        if (givenColor) {
            color = givenColor
        }

        let styles = {
            'width': progress + '%',
            'background-color': color
        };

        return styles
    }

    showDetails(workorderOrInventerId: number, workorder: boolean): void {
        this.isLoading = true
        if (workorder) {
            this.allStates.states.forEach(state => state.show = false)
            this.workOrderService.getWorkOrder(workorderOrInventerId)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(
                    data => {
                        this.showSidebar = true
                        this.selectedMarker = data
                        this.selectedMarker.imagecount = 0
                        this.type = data.type
                        Object.entries(data.statuses).forEach(([statusKey, status]) => {
                            status.count = status.images.length;
                            this.selectedMarker.imagecount += status.images.length;
                            if (status.images && status.images.length > 0) {
                                forkJoin(
                                    status.images.map((image) =>
                                        this.workOrderService.fetchImage(image.id).pipe(
                                            map(imageUrl => {
                                                image.url = imageUrl || 'defaultImageUrl'; // Update image URL or set default
                                                return image;
                                            }),
                                            catchError(error => {
                                                console.error('Error fetching image', error);
                                                return of(null);
                                            })
                                        )
                                    )
                                ).subscribe(images => {
                                    // All images for this status have been fetched and updated
                                    // console.log(`Images for status ${statusKey} updated`, images);
                                });
                            }
                        });
                        this.isLoading = false
                    }
                )
        } else {
            this.selectedMarker = this.inverters.find(i => i.id === workorderOrInventerId)
            this.showSidebar = true
            this.isLoading = false
            this.type = 2
        }
    }

    getStatusDefinition() {
        const state = this.allStates.states.find(element => element.id == this.selectedMarker.status_id);
        if (state) {
            return state.definition;
        }
        return null;
    }

    getStatusReports(stateId, selectedMarker) {
        const reports: any[] = []
        if (selectedMarker.reports) {
            for (let i = 0; i < selectedMarker.reports.length; i++) {
                const element = selectedMarker.reports[i]
                if (element.state == stateId) {
                    reports.push(element)
                }
            }
        return reports
        } else return []

    }

    checkLength(array, type) {
        return array.filter(element => element.type === type).length
    }


    closeSidebar(): void {
        this.showSidebar = false
        this.selectedState = null
    }

    getCenterCoordinates(): { latitude: number, longitude: number } {
        switch (this.customerId) {
            case 1:
                return {
                    latitude: 60.18208069645202,
                    longitude: 23.97548906518469
                };
            case 2:
                return {
                    latitude: 60.03611032213076,
                    longitude: 18.54047482522265
                };
            default:
                console.log("default was returned");
                return {
                    latitude: 0, // Default latitude
                    longitude: 0 // Default longitude
                };
        }
    }

    setUpCustomRectangleCanvas() {
        L.Canvas.include({
            _updateCustomRectangle: function (layer) {
                const ctx = this._ctx;
                const p = layer._point;
                const size = layer.options.size;
                const halfWidth = size[0] / 2;
                const halfHeight = size[1] / 2;

                ctx.save();
                ctx.beginPath();
                ctx.rect(p.x - halfWidth, p.y - halfHeight, size[0], size[1]);
                ctx.fillStyle = layer.options.fillColor;
                ctx.fill();
                ctx.strokeStyle = layer.options.color;
                ctx.lineWidth = layer.options.weight;
                ctx.stroke();
                ctx.restore();
            }
        });

        L.CustomRectangleMarker = L.CircleMarker.extend({
            options: {
                size: [20, 10], // width, height
            },

            _updatePath: function () {
                this._renderer._updateCustomRectangle(this);
            }
        });

        L.customRectangleMarker = function (latlng, options) {
            return new L.CustomRectangleMarker(latlng, options);
        };
    }


    setUpCustomTextMarkerCanvas() {
        L.Canvas.include({
            _updateCustomText: function (layer) {
                const ctx = this._ctx;
                const p = layer._point;
                const text = layer.options.text;
                const fontSize = layer.options.fontSize || 12;
                const fontColor = layer.options.fontColor || 'black';

                ctx.save();
                ctx.font = `${fontSize}px Arial`;
                ctx.fillStyle = fontColor;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(text, p.x, p.y);
                ctx.restore();
            }
        });

        L.CustomTextMarker = L.CircleMarker.extend({
            options: {
                text: '',
                fontSize: 12,
                fontColor: 'black'
            },

            _updatePath: function () {
                this._renderer._updateCustomText(this);
            }
        });

        L.customTextMarker = function (latlng, options) {
            return new L.CustomTextMarker(latlng, options);
        };

    }

    getAmount(state) {
        if (state.type === 3) {
            let amount = 0
            if (this.allStates) {
                this.allStates.states.forEach(element => {
                    if (element.type === 3) {
                        amount += element.workorders.amount
                    }
                });
            }
            return amount
        } else if (state.type === 2) {
            let amount = 0
            if (this.allStates) {
                this.allStates.states.forEach(element => {
                    if (element.type === 2) {
                        amount += element.workorders.amount
                    }
                });
            }
            return amount
        } else if (state.type === 1) {
            let amount = 0
            if (this.allStates) {
                this.allStates.states.forEach(element => {
                    if (element.type === 1) {
                        amount += element.workorders.amount
                    }
                });
            }
            return amount
        } else return state.workorders.totaltotal
    }

    getDoneAmount(state) {
        let amount = 0
        let states = this.allStates.states.filter(s => s.type === 3)
        states.forEach(element => {
            if (element.state !== 1 && element.state >= state.state) {
                amount += element.workorders.amount
            }
        });
        return amount
    }

}



