<div class="page-container">
    <div class="page-heading">
        <h3>
            <img src="assets/icons/groups_black_24dp.svg">
            <span class="page-title">{{'basic.teams' | translate}}</span>
        </h3>
    </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-center">
                    <load-spinner *ngIf="pageSpinner"></load-spinner>
                </div>
                <div *ngIf="!pageSpinner">
                    <div *ngIf="originalTeams.length > 0">
                        <div class="row">
                            <div class="col-4">
                                <div class="gray-text">{{'basic.selectState' | translate }}</div>
                                <div class="form-group">
                                    <select class="form-select max-content" (change)="filterStateChanged($event.target.value)">
                                        <option [value]="undefined">{{'basic.selectState' | translate }}</option>
                                        <option *ngFor="let state of allStates" [value]="state.id">{{state.definition}}</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="teams.length > 0">
                        <table class="teams-list">
                            <thead class="first-list-group-item">
                                <div class="row gray-text">
                                    <div role="button" scope="col" [appSort]="teams" data-order="desc" data-name="name" class="col-3">
                                        <span>{{'basic.team' | translate }}</span>
                                        <img src="assets/icons/unfold_more_gray_24dp.svg">
                                    </div>
                                    <div class="col-3">{{'teams.state' | translate }}</div>
                                    <div class="col-3">{{'teams.teamMembers' | translate }}</div>
                                    <div class="col-3">{{'teams.overallEfficiency' | translate }}</div>
                                </div>
                            </thead>
                            <tbody class="list-group-item" *ngFor="let item of teams; let i = index">
                                <a (click)="goToTeamDetail(item.id)">
                                    <div class="row team-detail">
                                        <div class="col-3">
                                            <div class="fw-bold black-text mt-3">{{item.name}}</div>
                                        </div>
                                        <div class="col-3">
                                            <div class="fw-bold black-text mt-3">{{item.definition}}</div>
                                        </div>
                                        <div class="col-3">
                                            <div *ngIf="item.workerscount > 0" >
                                                <div class="same-line mt-2">
                                                    <div class="fw-bold margin-top col-1">{{item.workerscount}}</div>
                                                    <div class="col-11 same-line">
                                                    <div *ngFor="let element of item.finalWorkers" class=" py-1 px-2 bold-text skill rounded-pill">
                                                        <div class="showFullName p-1" [hidden]="!element.showFullName">{{element.name}}</div>
                                                        <div (mouseenter)="element.showFullName = true" (mouseleave)="element.showFullName = false" class="gray-text">{{element.initials}}</div>
                                                    </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="item.workerscount == 0" class="red-text mt-3">{{'teams.noWorkers' | translate }}</div>
                                        </div>
                                        <div class="col-3">
                                            <div *ngIf="item.efficiencyNumber > 0">
                                                <app-ngx-circle-custom
                                                [efficiency]="item.efficiencyNumber"
                                                ></app-ngx-circle-custom>
                                            </div>
                                            <div *ngIf="item.efficiencyNumber == 0" class="red-text mt-3" >{{'teams.efficiencyZero' | translate }}</div>
                                        </div>
                                    </div>
                                </a>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="teams.length == 0">
                        <div>{{'basic.noTeams' | translate }}</div>
                    </div>
                    <button class="btn btn-primary text-white mt-3" id="open-create-new-team" data-bs-toggle="modal" data-bs-target="#createNewTeam">
                        <span class="me-1">+</span>
                        <span>{{'teams.createNewTeam' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- Modal create team -->
        <div class="modal fade" id="createNewTeam" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4>
                            <img src="assets/icons/groups_black_24dp.svg" class="image-reposition-groups">
                            <span id="create-new-team-title" class="ms-1">{{'teams.createNewTeam' | translate }}</span>
                        </h4>
                        <load-spinner *ngIf="popupSpinner"></load-spinner>
                        <button [hidden]="true" data-bs-dismiss="modal" (click)="cancelCreateTeam()" id="hiddenClose"></button>
                        <div *ngIf="!popupSpinner">
                            <form ngNativeValidate (ngSubmit)="createNewTeam()">
                                <div class="gray-text">{{'teams.teamName' | translate }}</div>
                                <input id="team-name-input" type="text" class="form-control max-content" required [(ngModel)]="createTeamName" [ngModelOptions]="{standalone: true}"><br>
                                <hr>
                                <div class="gray-text">{{'teams.state' | translate }}</div>
                                <select class="form-select" (change)="stateChange($event.target.value)">
                                    <option [selected] [value]="0">Select status</option>
                                    <option *ngFor="let state of teamStates; let i = index" [value]="state.id">{{state.definition}}</option>
                                </select>
                                <hr>
                                <h6>{{'teams.selectWorkersFromList' | translate }} ({{allWorkers.length}}) </h6>
                                <div class="all-workers-list">
                                    <div *ngFor="let worker of allWorkers; let i = index" class="height-list" [ngClass]="i == 0 ? 'workers-border-all' : 'workers-border-no-top' " >
                                        <div class="row">
                                            <div class="col-6">
                                                <app-checkbox
                                                (isChecked)="workerClicked(worker.id)"
                                                [text]="worker.firstname + ' ' + worker.lastname"
                                                [checkbox]="worker.checked"
                                                ></app-checkbox>
                                            </div>
                                            <div class="col-6">
                                                <app-ngx-circle-custom
                                                [efficiency]="worker.efficiencyNumber"
                                                ></app-ngx-circle-custom>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3 selected-workers" *ngIf="selectedWorkers.length > 0">
                                    <h6>{{'basic.selectedWorkers' | translate }}</h6>
                                    <div *ngFor="let selectedWorker of selectedWorkers; let i = index" class="py-2 ps-2" [ngClass]="i == 0 ? 'selected-workers-borders' : 'selected-workers-border-no-top'">
                                        <div class="row">
                                            <div class="blue-text col-1 mt-3">{{i + 1}}.</div>
                                            <div class="ms-2 col-3 mt-3">{{selectedWorker.firstname}} {{selectedWorker.lastname}}</div>
                                            <div class="col-2">
                                                <app-ngx-circle-custom
                                                [efficiency]=selectedWorker.efficiencyNumber
                                                ></app-ngx-circle-custom>
                                            </div>
                                            <div class="col-5 fit-content">
                                                <div class="same-line">
                                                    <div *ngFor="let skill of selectedWorker.skills" class="margin-top-skill skill d-flex justify-content-center font-size rounded-pill gray-text" >
                                                        <div>{{skill.name}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2" *ngIf="showTotalEfficiency && currentTeamEfficiency > 0">
                                    <span class="fw-bold">{{'teams.overallEfficiency' | translate }}: </span>
                                    <app-ngx-circle-custom
                                    [efficiency]="currentTeamEfficiency"
                                    ></app-ngx-circle-custom>
                                </div>
                                <load-spinner [hidden]="showTotalEfficiency || currentTeamEfficiency === 0"></load-spinner>
                                <button id="modal-submit" type="submit" class="btn btn-primary mt-2">{{'basic.save' | translate }}</button>
                                <button type="button" class="btn btn-secondary ms-1 mt-2" (click)="cancelCreateTeam()" data-bs-dismiss="modal" id="createNewTeamClose">{{'basic.cancel' | translate }}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
